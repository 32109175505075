import React from "react";

const Footer = () => {
  return (
    <footer>
      <div id="footer-content">
        <div className="footer-wrapper">
          <div className="contact-info">
            <p>
              <strong>Nathaniel McDonald</strong>
              <br />
              mcdonaldnathaniel7@gmail.com
            </p>
          </div>
          <div className="footer-links">
            <a href="/privacy-policy">Privacy Policy</a>
            <button
              className="btn btn-link"
              type="button"
              onClick={() => window.scrollTo(0,0)}
            >
              Back to Top
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
