import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import tree1 from "../../../static/images/christmas-tree1.svg";
import tree2 from "../../../static/images/christmas-tree2.svg";
import styles from "./DigiDragonChristmasCard.module.css";
import lights1 from "../../../static/images/christmas-lights-1.svg";
import lights2 from "../../../static/images/christmas-lights-2.svg";
import Gift from "../Gift/Gift";
import Modal from "../../../components/Modal/Modal";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import giftBow from "../../../static/images/gift-bow.svg";
import zeldaImg from "../../../static/images/goofy-dragon-smile.gif";
import dragonImgs from "../DragonImageImports";
import '../DragonAnim.css';

const DigiDragonChristmasCard = () => {
  const [showTagModal, setShowTagModal] = useState(null);
  const [showGiftModal, setShowGiftModal] = useState(null);
  const [openUpGift, setOpenUpGift] = useState(false);

  useEffect(() => {
    document.querySelector("header").style.display = "none";
    document.querySelector("footer").style.display = "none";
  }, []);

  const closeTagModal = () => {
    setShowTagModal(false);
  };

  const closeGiftModal = () => {
    setShowGiftModal(false);
  };

  const openGift = () => {
    setOpenUpGift(true);
    setTimeout(() => setShowGiftModal(true), 2000);
    closeTagModal();
  };

  let giftTagModal = showTagModal ? (
    <Modal
      onHideModal={closeTagModal}
      className={styles.giftTag}
      darkMode={false}
    >
      <div className={styles.giftTagWrapper}>
        <div className={styles.giftTagBody}>
          <span>To: {showTagModal}</span>
          <span>Open this present?</span>
          <span>
            <PrimaryButton onClick={openGift} label="Yes" />
            <PrimaryButton onClick={closeTagModal} label="No" />
          </span>
        </div>
      </div>
    </Modal>
  ) : (
    ""
  );

  let giftDetails = (
    <div>
      <img src={zeldaImg} alt="Legend of Zelda reference" />
      <p>Happy Holidays Team!</p>
    </div>
  );

  let giftPresentationModal = showGiftModal ? (
    <div className={styles.giftPresentationModalWrapper}>
      <Modal
        onHideModal={closeGiftModal}
        className={styles.giftPresentation}
        darkMode={false}
      >
        <div className={styles.giftBow}>
          <img src={giftBow} alt="gift bow" />
          <img src={giftBow} alt="gift bow" />
        </div>
        {giftDetails}
        <PrimaryButton onClick={closeGiftModal} label="Close" />
      </Modal>
    </div>
  ) : (
    ""
  );

  let firstGift = (
    <div>
      {openUpGift ? "" : <span className={styles.clickMeText}>Click me</span>}
      <Gift opened={openUpGift} onClick={() => setShowTagModal("The DigiDragons")} />
    </div>
  );

  return (
    <Container className={styles.cardWrapper}>
      <div className={styles.backdrop} />
      {giftTagModal}
      {giftPresentationModal}
      <Row>
        <Col>
          <div className={styles.merryChristmasText}>
            <svg className={styles.lgText}>
              <path id="curve1" d="M 20 150 Q 200 0 400 150" />
              <text>
                <textPath xlinkHref="#curve1">Merry Christmas!</textPath>
              </text>
            </svg>
            <svg className={styles.smText}>
              <path id="curve2" d="M 30 100 Q 130 0 260 100" />
              <text>
                <textPath xlinkHref="#curve2">Merry Christmas!</textPath>
              </text>
            </svg>
          </div>
          <div className={styles.christmasLights}>
            <img src={lights1} alt="Christmas Lights" />
            <img src={lights2} alt="Christmas Lights" />
          </div>
          <div id="dragonTreeImgContainer" className={styles.christmasTree}>
            <img className="slide" src={tree1} alt="Christmas Tree" />
            <img className="slide" src={tree2} alt="Christmas Tree" />
            <img className="slide" src={dragonImgs.oneA} alt="Christmas Tree" />
            <img className="slide" src={dragonImgs.oneB} alt="Christmas Tree" />
            <img className="slide" src={dragonImgs.twoA} alt="Christmas Tree" />
            <img className="slide" src={dragonImgs.twoB} alt="Christmas Tree" />
            <img
              className="slide"
              src={dragonImgs.threeA}
              alt="Christmas Tree"
            />
            <img
              className="slide"
              src={dragonImgs.threeB}
              alt="Christmas Tree"
            />
            <img
              className="slide"
              src={dragonImgs.fourA}
              alt="Christmas Tree"
            />
            <img
              className="slide"
              src={dragonImgs.fourB}
              alt="Christmas Tree"
            />
            <img
              className="slide"
              src={dragonImgs.fiveA}
              alt="Christmas Tree"
            />
            <img className="slide" src={dragonImgs.sixB} alt="Christmas Tree" />
            <img
              className="slide"
              src={dragonImgs.fiveA}
              alt="Christmas Tree"
            />
            <img className="slide" src={dragonImgs.sixB} alt="Christmas Tree" />
            <img className="slide" src={dragonImgs.sevenA} alt="Christmas Tree" />
            <img className="slide" src={dragonImgs.sevenB} alt="Christmas Tree" />
            <img className="slide" src={dragonImgs.eightA} alt="Christmas Tree" />
            <img className="slide" src={dragonImgs.eightB} alt="Christmas Tree" />
            <img className="slide" src={dragonImgs.nineA} alt="Christmas Tree" />
            <img className="slide" src={dragonImgs.nineB} alt="Christmas Tree" />
            <img className="slide" src={dragonImgs.tenA} alt="Christmas Tree" />
            <img className="slide" src={dragonImgs.tenB} alt="Christmas Tree" />
            <img className="slide" src={dragonImgs.elevenA} alt="Christmas Tree" />
            <img className="slide" src={dragonImgs.elevenB} alt="Christmas Tree" />
            <div className={styles.giftsWrapper}>{firstGift}</div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DigiDragonChristmasCard;
