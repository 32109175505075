import { useState } from "react";

const SkillBadge = (props) => {
    const [rotated, setRotated] = useState('');

    const rotateBadge = (event) => {
        if(rotated === '')
            setRotated('rotate-badge');
        else setRotated('');
    }
  
      return(
        <div className={`${props.id} skill-badge ${rotated}`} onClick={rotateBadge}>
          <img src={props.src} alt={props.id}/>
          <div className="skill-description-wrapper">
            <p className="no-cursor">
              {props.description}
            </p>
          </div>
        </div>
      )
  };

  export default SkillBadge;