import React, { useContext } from 'react';
import PlayingCard from '../../../components/PlayingCard/PlayingCard';
import { FreeCellContext} from '../../../store/FreeCellContext';
import styles from './Cascade.module.css';

const Cascade = (props) => {
    const freeCellContext = useContext(FreeCellContext);
    let cascade = [];
    let cardIndex = 0;

    const cardClickHandler = (cardIndex) => {
        if(freeCellContext.cardSelection && (freeCellContext.cardSelection !== 2 || props.cascadeIndex !== freeCellContext.cardSelection[0])) {
            // try to add card selection to cascade
            let destinationIndex = freeCellContext.gameState[2][props.cascadeIndex].length -1;
            let sourceCard = freeCellContext.gameState[freeCellContext.cardSelection[2]][freeCellContext.cardSelection[0]][freeCellContext.cardSelection[1]];
            let destinationCard;
            if(freeCellContext.gameState[2][props.cascadeIndex].length === 0) {
                destinationCard = { rank: 14, suit: sourceCard.suit % 2 + 1 };
            } else {
                destinationCard = freeCellContext.gameState[2][props.cascadeIndex][destinationIndex];
            }

            if(destinationCard.rank === sourceCard.rank + 1 && sourceCard.suit % 2 !== destinationCard.suit % 2) {
                console.log("successful move");
                let gameAction = {
                    type: "moveToTableau",
                    source: freeCellContext.cardSelection,
                    destination: props.cascadeIndex
                }
                
                freeCellContext.dispatchGameState(gameAction);
            }
            freeCellContext.setCardSelection(null);
        } else if(freeCellContext.cardSelection && props.cascadeIndex === freeCellContext.cardSelection[0]) {
            freeCellContext.setCardSelection(null);
        } else if(freeCellContext.gameState[2][props.cascadeIndex].length > 0) {
            let tableauIndex = freeCellContext.gameState[2][props.cascadeIndex].length -1;

            while(tableauIndex > 0 && freeCellContext.gameState[2][props.cascadeIndex][tableauIndex].rank + 1 === freeCellContext.gameState[2][props.cascadeIndex][tableauIndex-1].rank && freeCellContext.gameState[2][props.cascadeIndex][tableauIndex].suit % 2 !== freeCellContext.gameState[2][props.cascadeIndex][tableauIndex-1].suit % 2) {
                tableauIndex--;
            }

            if(cardIndex >= tableauIndex) {
                freeCellContext.setCardSelection([props.cascadeIndex, cardIndex, 2]);
            } else {
                freeCellContext.setCardSelection([props.cascadeIndex, tableauIndex, 2]);
            }
        }
    }
    
    freeCellContext.gameState[2][props.cascadeIndex].forEach((card, index) => {
        cascade.push(
            <div className={styles.cardWrapper} key={`card-${props.cascadeIndex}-${index}`} >
                <PlayingCard 
                    rank={card.rank}
                    suit={card.suit}
                    faceGraphic={card.faceGraphic}
                    cardIndex={cardIndex}
                    highlighted={freeCellContext.cardSelection && freeCellContext.cardSelection[2] === 2 && props.cascadeIndex === freeCellContext.cardSelection[0] && freeCellContext.cardSelection[1] <= cardIndex}
                    onClick={cardClickHandler}
                />
            </div>
        )
        cardIndex++;
    });

    return (
        cascade.length > 0 ?
        <div className={styles.cascade}>{cascade}</div>
        :
        <div className={styles.emptyCascade} onClick={cardClickHandler}></div>
    )
}

export default Cascade