let DUMMY_EXPENSES = [
    {
      id: 'e1',
      title: 'Toilet Paper',
      amount: 94.12,
      date: new Date(2020, 7, 14),
      category: 'Grocery',
      subcategory: 'Toiletries',
      creditCard: 'N/A',
      cashBack: 'N/A',
    },
    { 
      id: 'e2', 
      title: 'New TV', 
      amount: 799.49, 
      date: new Date(2021, 2, 12),
      category: 'Appliance',
      subcategory: 'TV',
      creditCard: 'AMEX 1023',
      cashBack: '2%',
    },
    {
      id: 'e3',
      title: 'Car Insurance',
      amount: 294.67,
      date: new Date(2021, 2, 28),
      category: 'Auto',
      subcategory: 'Insurance',
      creditCard: 'AMEX 1023',
      cashBack: 'N/A',
    },
    {
      id: 'e4',
      title: 'New Desk (Wooden)',
      amount: 450,
      date: new Date(2021, 5, 12),
      category: 'Office',
      subcategory: 'Furniture',
      creditCard: 'N/A',
      cashBack: 'N/A',
    },
  ];

  export default DUMMY_EXPENSES;