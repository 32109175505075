import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import styles from "./Minesweeper.module.css";
import Gameboard from "./Gameboard/Gameboard";

const Minesweeper = () => {
  const [gamePhase, setGamePhase] = useState("initial");
  const [mineCount, setMineCount] = useState(0);
  const [gameBoardState, setGameBoardState] = useState([]);
  const [activeCells, setActiveCells] = useState([]);
  const [flagCount, setFlagCount] = useState(0);

  const rulesClickHandler = () => {
    window.open(
      "https://en.wikipedia.org/wiki/Minesweeper_(video_game)",
      "_blank"
    );
  };

  const initializeActiveCells = (boardDims) => {
    let ac = {};
    for (let i = 0; i < boardDims[0]; i++) {
      ac[i] = [];
      for (let j = 0; j < boardDims[1]; j++) {
        ac[i][j] = 0;
      }
    }

    setActiveCells(ac);
  };

  const initializeGameboard = (boardDims, count) => {
    let gb = {};
    for (let i = 0; i < boardDims[0]; i++) {
      gb[i] = [];
      for (let j = 0; j < boardDims[1]; j++) {
        gb[i][j] = 0;
      }
    }

    // Place mines
    for (let k = 0; k < count; k++) {
      let randX = null;
      let randY = null;

      do {
        randX = Math.floor(Math.random() * boardDims[0]);
        randY = Math.floor(Math.random() * boardDims[1]);
      } while (gb[randX][randY] === -1);
      gb[randX][randY] = -1;
      for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
            if(randX + 1 - i < boardDims[0] && randX + 1 - i >= 0 && randY + 1 - j < boardDims[1] && randY + 1 - j >= 0 && gb[randX + 1 - i][randY + 1 - j] !== -1) {
              gb[randX + 1 - i][randY + 1 - j]++;
            }
        }
      }
    }

    setGameBoardState(gb);
  };

  const getBoardDims = (numberOfMines) => {
    let boardDims = [9, 9];
    if (numberOfMines === 40) {
      boardDims = [16, 16];
    } else if (numberOfMines === 99) {
      boardDims = [24, 16];
    }

    return boardDims;
  };

  const mineCountClickHandler = (count) => {
    setMineCount(count);
    setGamePhase("inProgress");
    let boardDims = getBoardDims(count);

    initializeActiveCells(boardDims);
    initializeGameboard(boardDims, count);
  };

  const newGameClickHandler = () => {
    setGamePhase("pregame");
    setMineCount(0);
    setFlagCount(0);
    setGameBoardState([]);
    setActiveCells([]);
  };

  const clearFlagsHandler = () => {
    let currentActive = {};
    for (const key in activeCells) {
      currentActive[key] = [...activeCells[key]];
      for (const index in currentActive[key]) {
        if (currentActive[key][index] === 2) {
          currentActive[key][index] = 0;
        }
      }
    }
    
    setFlagCount(0);
    setActiveCells(currentActive);
  };

  const resetGameHandler = () => {
    let boardDims = getBoardDims(mineCount);
    setFlagCount(0);
    initializeActiveCells(boardDims);
    setGamePhase("inProgress");
  };

  const checkForWin = (ac) => {
      let inactiveCellCount = 0;
      for(const key in ac) {
          for(const index in ac[key]) {
              if(ac[key][index] === 0 || ac[key][index] === 2) {
                inactiveCellCount++;
              }
          }
      }

      if(inactiveCellCount === mineCount) {
          setGamePhase("winner");
      }
  }

  const revealCellClickHandler = (xIndex, yIndex) => {
    if (gamePhase !== "gameOver" && activeCells[xIndex][yIndex] !== 1) {
      if (gamePhase === "settingFlags" && activeCells[xIndex][yIndex] !== 1) {
        if (activeCells[xIndex][yIndex] === 0 && flagCount < mineCount) {
          setActiveCells((prevState) => {
            let temp = [...prevState[xIndex]];
            temp[yIndex] = 2;
            return {
              ...prevState,
              [xIndex]: temp,
            };
          });
          setFlagCount((prevState) => {
            return prevState + 1;
          });
        } else if (activeCells[xIndex][yIndex] === 2) {
          setActiveCells((prevState) => {
            let temp = [...prevState[xIndex]];
            temp[yIndex] = 0;
            return {
              ...prevState,
              [xIndex]: temp,
            };
          });
          setFlagCount((prevState) => {
            return prevState - 1;
          });
        }
      } else if (
        gamePhase === "inProgress" &&
        activeCells[xIndex][yIndex] !== 2
      ) {
        if (gameBoardState[xIndex][yIndex] === -1) {
          setGamePhase("gameOver");
        }

        setActiveCells((prevState) => {
          let activeCellsUpdatedState = {};
          for (const key in prevState) {
            activeCellsUpdatedState[key] = [...prevState[key]];
          }
          activeCellsUpdatedState[xIndex][yIndex] = 1;
          if (gameBoardState[xIndex][yIndex] === 0) {
            // Reveal surrounding zeros if zero was selected
            let zeroValueCellQueue = [[xIndex, yIndex]];

            while (zeroValueCellQueue.length > 0) {
              let currentX = zeroValueCellQueue[0][0];
              let currentY = zeroValueCellQueue[0][1];
              let boardDims = getBoardDims(mineCount);
              if (
                currentX + 1 < boardDims[0] &&
                gameBoardState[currentX + 1][currentY] !== -1 &&
                activeCellsUpdatedState[currentX + 1][currentY] === 0
              ) {
                activeCellsUpdatedState[currentX + 1][currentY] = 1;
                if (gameBoardState[currentX + 1][currentY] === 0) {
                  zeroValueCellQueue.push([currentX + 1, currentY]);
                }
              }
              if (
                currentX - 1 >= 0 &&
                gameBoardState[currentX - 1][currentY] !== -1 &&
                activeCellsUpdatedState[currentX - 1][currentY] === 0
              ) {
                activeCellsUpdatedState[currentX - 1][currentY] = 1;
                if (gameBoardState[currentX - 1][currentY] === 0) {
                  zeroValueCellQueue.push([currentX - 1, currentY]);
                }
              }
              if (
                currentY + 1 < boardDims[1] &&
                gameBoardState[currentX][currentY + 1] !== -1 &&
                activeCellsUpdatedState[currentX][currentY + 1] === 0
              ) {
                activeCellsUpdatedState[currentX][currentY + 1] = 1;
                if (gameBoardState[currentX][currentY + 1] === 0) {
                  zeroValueCellQueue.push([currentX, currentY + 1]);
                }
              }
              if (
                currentY - 1 >= 0 &&
                gameBoardState[currentX][currentY - 1] !== -1 &&
                activeCellsUpdatedState[currentX][currentY - 1] === 0
              ) {
                activeCellsUpdatedState[currentX][currentY - 1] = 1;
                if (gameBoardState[currentX][currentY - 1] === 0) {
                  zeroValueCellQueue.push([currentX, currentY - 1]);
                }
              }

              zeroValueCellQueue.shift();
            }
          }
          
          checkForWin(activeCellsUpdatedState);

          return activeCellsUpdatedState;
        });
      }
    }
  };

  const gameRulesButton = (
    <PrimaryButton onClick={rulesClickHandler} label="Game Rules" />
  );

  let gameButtons = (
    <div className={styles.gameButtonsWrapper}>
      <div>
        <PrimaryButton
          onClick={() => setGamePhase("pregame")}
          label="Start Game"
        />
        {gameRulesButton}
      </div>
    </div>
  );

  if (gamePhase === "pregame") {
    gameButtons = (
      <div className={styles.gameButtonsWrapper}>
        <p>Please choose number of mines.</p>
        <div>
          <PrimaryButton onClick={() => mineCountClickHandler(10)} label="10" />
          <PrimaryButton onClick={() => mineCountClickHandler(40)} label="40" />
          <PrimaryButton onClick={() => mineCountClickHandler(99)} label="99" />
        </div>
        <div>{gameRulesButton}</div>
      </div>
    );
  } else if (gamePhase === "inProgress") {
    gameButtons = (
      <div className={styles.gameButtonsWrapper}>
        <div>
          <PrimaryButton onClick={newGameClickHandler} label="New Game" />
          {gameRulesButton}
          <PrimaryButton
            onClick={() => setGamePhase("settingFlags")}
            label="Set Flags"
          />
          <PrimaryButton label="Clear Flags" onClick={clearFlagsHandler} />
          <PrimaryButton label="Reset Game" onClick={resetGameHandler} />
        </div>
      </div>
    );
  } else if (gamePhase === "settingFlags") {
    gameButtons = (
      <div className={styles.gameButtonsWrapper}>
        <div>
          <p>Click on a cell to place a flag.</p>
        </div>
        <div>
          {gameRulesButton}
          <PrimaryButton
            onClick={() => setGamePhase("inProgress")}
            label="Done"
          />
        </div>
      </div>
    );
  } else if (gamePhase === "gameOver") {
    gameButtons = (
      <div className={styles.gameButtonsWrapper}>
        <div className={styles.gameOverText}>
          <p>Game Over!</p>
        </div>
        <div>
          <PrimaryButton onClick={newGameClickHandler} label="New Game" />
          <PrimaryButton label="Reset Game" onClick={resetGameHandler} />
          {gameRulesButton}
        </div>
      </div>
    );
  } else if (gamePhase === "winner") {
    gameButtons = (
      <div className={styles.gameButtonsWrapper}>
        <div className={styles.gameOverText}>
          <p>You Win!</p>
        </div>
        <div>
          <PrimaryButton onClick={newGameClickHandler} label="New Game" />
          {gameRulesButton}
        </div>
      </div>
    );
  }

  return (
    <Container>
      <h1>Minesweeper</h1>
      <Row>
        <Col>
          {mineCount > 0 ? (
            <Gameboard
              gameState={gameBoardState}
              activeGameCells={activeCells}
              onRevealClick={revealCellClickHandler}
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row>
        <Col>{gameButtons}</Col>
      </Row>
    </Container>
  );
};

export default Minesweeper;
