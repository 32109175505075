import react, { useReducer, useState } from 'react';
import aceHeartsImage from '../static/images/mothersDayFreeCell/IMG-2620.JPG';
import jackHeartsImage from '../static/images/mothersDayFreeCell/IMG-2652.JPG';
import queenHeartsImage from '../static/images/mothersDayFreeCell/IMG-2776.JPG';
import kingHeartsImage from '../static/images/mothersDayFreeCell/FB_IMG_1651981935015.jpg';
import aceCoffeeImage from '../static/images/mothersDayFreeCell/IMG_9011.JPG';
import jackCoffeeImage from '../static/images/mothersDayFreeCell/IMG_9012.JPG';
import queenCoffeeImage from '../static/images/mothersDayFreeCell/IMG_9027.JPG';
import kingCoffeeImage from '../static/images/mothersDayFreeCell/IMG_9035.JPG';
import pawAceImage from '../static/images/cassie-you-gonna-eat-that.jpeg';
import pawJackImage from '../static/images/mothersDayFreeCell/IMG-9542.JPG';
import pawQueenImage from '../static/images/mothersDayFreeCell/IMG-9540.JPG';
import pawKingImage from '../static/images/loki-sleeping.jpg';
import pepperAceImage from '../static/images/mothersDayFreeCell/indoor-gardening.png';
import pepperJackImage from '../static/images/mothersDayFreeCell/IMG_1341.jpeg';
import pepperQueenImage from '../static/images/mothersDayFreeCell/julie-mom-garden.png';
import pepperKingImage from '../static/images/mothersDayFreeCell/IMG_1340.jpeg';

const cardImages = [
    aceHeartsImage,
    pawAceImage,
    pepperAceImage,
    aceCoffeeImage,
    jackHeartsImage,
    pawJackImage,
    pepperJackImage,
    jackCoffeeImage,
    queenHeartsImage,
    pawQueenImage,
    pepperQueenImage,
    queenCoffeeImage,
    kingHeartsImage,
    pawKingImage,
    pepperKingImage,
    kingCoffeeImage,
]

const FreeCellContext = react.createContext({
    gameState: [],
    cardSelection: null,
});

const gameStateReducer = (gameState, action) => {
    switch(action.type) {
        case 'moveToCell': {
            let src = action.source;
            let dest = action.destination;
            let updatedState = [...gameState];

            let cardToMove = updatedState[src[2]][src[0]][src[1]];

            updatedState[src[2]][src[0]].pop();
            updatedState[1][dest] = updatedState[1][dest].concat(cardToMove);

            return updatedState;
        }
        case 'moveToFoundation': {
            let src = action.source;
            let dest = action.destination;
            let updatedState = [...gameState];

            let cardToMove = updatedState[src[2]][src[0]][src[1]];

            updatedState[src[2]][src[0]].pop();
            updatedState[0][dest] = updatedState[0][dest].concat(cardToMove);

            return updatedState;
        }
        case 'moveToTableau': {
            let src = action.source;
            let dest = action.destination;
            let updatedState = [...gameState];
            let cardsToMove = updatedState[src[2]][src[0]].slice(src[1]);
            
            updatedState[src[2]][src[0]].splice(src[1], cardsToMove.length);
            updatedState[2][dest] = updatedState[2][dest].concat(cardsToMove);
            
            return updatedState;
        }
    }
}

function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

const getInitialGameState = () => {
    let gameState = [
        [[],[],[],[]],
        [[],[],[],[]],
        []
    ];
    let deck = [];

    let imageIndex = 0;
    for(let i = 1; i < 14; i++) {
        for(let j = 0; j < 4; j++) {
            let card = { rank: i, suit: j };
            if(i === 1 || i >= 11) {
                card.faceGraphic = cardImages[imageIndex];
                imageIndex++;
            }
            deck.push(card);
        }
    }

    shuffle(deck);

    let currentIndex = 0;
    for(let i = 0; i < 4; i++) {
        let cascade = [];
        for(let j = 0; j < 7; j++) {
            cascade.push(deck[currentIndex]);
            currentIndex++;
        }
        gameState[2].push(cascade);
    }

    for(let i = 0; i < 4; i++) {
        let cascade = [];
        for(let j = 0; j < 6; j++) {
            cascade.push(deck[currentIndex]);
            currentIndex++;
        }
        gameState[2].push(cascade);
    }

    return gameState;
}

export const FreeCellContextProvider = (props) => {
    const [gameState, dispatchGameState] = useReducer(gameStateReducer, getInitialGameState());
    const [cardSelection, setCardSelection] = useState(null);

    return (
        <FreeCellContext.Provider
            value={{
                gameState,
                cardSelection,
                dispatchGameState,
                setCardSelection
            }}
        >
            {props.children}
        </FreeCellContext.Provider>
    );
}

export { FreeCellContext };
