import React, { useEffect, useContext } from "react";
import SkillsInfo from "../../components/Skills/SkillsInfo";
import ExperienceInfo from "../../components/Experience/ExperienceInfo";
import { AppContext } from "../../store/AppContext";
import { Link } from "react-router-dom";

const Resume = (props) => {
  const appCtx = useContext(AppContext);

  useEffect(() => {
    let scrollListener = () => {
      const skillsInfoElement = document.getElementById("skills-info");
      const educationWrapperElement =
        document.getElementById("education-wrapper");

      if (window.scrollY > 20) {
        if(educationWrapperElement){
          educationWrapperElement.classList.add("opacity-1");
        }
      }
      if (window.scrollY > 180) {
        if(skillsInfoElement){
          setTimeout(function () {
            skillsInfoElement.classList.add("opacity-1");
          }, 300);
        }
      }
    }

    window.addEventListener("scroll", scrollListener);

    appCtx.setHeadingBanner("Welcome");

    return function cleanup() {
      window.removeEventListener("scroll", scrollListener);
      appCtx.setHeadingBanner("");
    }
  }, [appCtx]);

  return (
    <div>
      <div id="introduction">
        <h2>About</h2>
        <p>
          This site serves as a portfolio to demonstrate programs and projects I
          have created. Much of the code used for the games and other apps can
          be found on my github{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.github.com/nnmcdonald"
          >
            here
          </a>
          . Please view my programs and projects using the menus and links above
          or continue below to view my resume information in more detail.
          <br />
        </p>
        <h2>Recent Site Updates</h2>
        <p>
          The site was originally designed with the Django framework and deployed
          with AWS Elastic Beanstalk. As I've learned more about AWS resources, and since my free tier
          has expired, it has become apparent that this site would benefit from a serverless
          deployment. Over time I had also integrated multiple React projects with
          my Django code, however to facilitate a serverless architecture I have since
          converted the entire site into a React app. Read more about my AWS architecture 
          design <Link to="/about/aws-architecture">here</Link>.
        </p>
      </div>
      <div className="intro-overlap">
        <a href="#education-anchor">Education</a>
        <a href="#skills-anchor">Skills</a>
        <a href="#experience-anchor">Experience</a>
      </div>
      <div className="resume-wrapper">
        <div id="education-wrapper" style={{ position: "relative" }}>
          <span id="education-anchor" className="anchor"></span>
          <div className="education-info row">
            <div className="row">
              <i className="fas fa-graduation-cap fa-7x mr-2"></i>
              <p className="school-description">
                Bachelor of Science in Computer Science
                <br />
                with a minor in Mathematics
                <br />
                University of Alaska Anchorage
                <br />
                May 2019
                <br />
                GPA: 3.14
              </p>
            </div>

            <h4>Topics Studied:</h4>
            <p>
              Data Structures and Algorithms, Databases, Networking, Software
              Development, Operating Systems, Hardware Concepts, Programming
              Language Concepts, Artificial Intelligence, Machine Learning,
              Partial Differential Equations, Graph Theory, Probability and
              Statistics{" "}
            </p>
          </div>

          <div className="education-info row">
            <p className="school-description">
              Baylor University
              <br />
              Attended Summer 2021
              <br />
              GPA: 3.82
            </p>

            <p>
              I attended an online Masters program in Computer Science through
              Baylor University for one semester. During my time at Baylor I
              studied courses in AI/Machine Learning and Advanced Databases.
            </p>
          </div>
        </div>
        <SkillsInfo />
        <ExperienceInfo />
      </div>
    </div>
  );
};

export default Resume;
