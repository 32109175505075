import React, { useContext } from 'react';
import { PhotoGalleryLayoutContext } from '../../../store/PhotoGalleryLayoutContext';
import Category from '../../../containers/PhotoGallery/CategoryFilters/Category';
import styles from './PhotoCard.module.css';

const PhotoCard = (props) => {
    const layoutContext = useContext(PhotoGalleryLayoutContext);

    return (
        <div className={`${styles['photo-card']} ${styles[layoutContext.layoutType]}`}>
            <div className={styles['photo-card__image']}>
                <img alt={props.description} src={props.img_path} />
            </div>
            <div className={styles['photo-card__info']}>
                <div className={styles['photo-card__categories']}>
                    {
                        props.categories.map(category => (
                            <Category 
                                key={category}
                                categoryName={category}
                                clickable={false}
                            />
                        ))
                    }
                </div>
                <div className={styles['photo-card__albums']}>
                    
                </div>
                <h3 className={styles['title']}>{props.title}</h3>
                <p className={styles['description']}>{props.description}</p>
            </div>
        </div>
    )
}

export default PhotoCard
