import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { AppContext }from "../../store/AppContext";
import ModalsWrapper from "../../components/Modal/ModalsWrapper";
import Modal from '../../components/Modal/Modal';

const Header = () => {
  const appCtx = useContext(AppContext);
  const [activeArlisModal, setActiveArlisModal] = useState(false)
  const [expandedNav, setExpandedNav] = useState(false);

  useEffect(() => {
    if(localStorage.getItem("darkMode") === 'true') {
      document.getElementById('dark-mode__switch').checked = true;
      appCtx.setDarkMode(true);
    }
  }, [appCtx])

  const darkModeClickHandler = (event) => {
    localStorage.setItem("darkMode", event.target.checked);
    appCtx.setDarkMode(event.target.checked);
  }

  const hideArlisModal = () => {
    setActiveArlisModal(false);
  }

  const showArlisModal = (event) => {
    event.preventDefault();
    setActiveArlisModal(true);
  }

  const arlisClickHandler = () => {
    setActiveArlisModal(false);
    window.open("https://www.arlis.org", "_blank");
  }

  const toggleNavHandler = () => {
    setExpandedNav((prevState) => !prevState);
  }

  const collapseNavHandler = () => {
    setExpandedNav(false);
    window.scrollTo(0,0)
  }
  
  let currentTheme = appCtx.darkMode ? "dark" : "light";

  return (
    <React.Fragment>
      <ModalsWrapper>
        { 
          activeArlisModal ? 
          <Modal onHideModal={hideArlisModal} darkMode={appCtx.darkMode}>
            <div>
              <p>
                ARLIS.org is a site that I developed and deployed with the Django 
                framework and Apache. This new site fully replaced their existing site
                and addressed many redundancies and problems with the old site. 
                I am happy to say, the current site that I developed has been in 
                production longer than the site it replaced!
              </p>
              <button onClick={arlisClickHandler} className="custom-btn__primary">View ARLIS.org</button>
            </div>
          </Modal> : '' 
        }
      </ModalsWrapper>
      <header id="header-content" className="sticky-top">
        <div className="sticky-top header-wrapper justify-content-between">
          <Navbar expanded={expandedNav} className="justify-content-between" id="navbar" expand="lg" variant={currentTheme}>
            <div className="header-brand-section">
              <Navbar.Brand onClick={collapseNavHandler} as={Link} to="/" id="header-brand">NM</Navbar.Brand>
              <div className="social ml-auto">
                <a
                  className="btn-social"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.github.com/nnmcdonald"
                >
                  <i className="fab fa-github fa-3x"></i>
                </a>
                <a
                  className="btn-social"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/nathaniel-mcdonald-1511b5187/"
                >
                  <i className="fab fa-linkedin fa-3x"></i>
                </a>
              </div>
            </div>
            <div className="header-navigation-section">
              <div className="dark-mode__options">
                <span className="dark-mode__desc">Dark Mode</span>
                <label className="switch">
                  <input
                    id="dark-mode__switch"
                    type="checkbox"
                    onClick={darkModeClickHandler}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div>
                <Navbar.Toggle onClick={toggleNavHandler} aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <NavDropdown bsPrefix="btn-link btn-nav" title="React Projects" id="basic-nav-dropdown">
                      <NavDropdown.Item onClick={collapseNavHandler} as={Link} to="/react/photo-gallery">
                        Photography Gallery
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={collapseNavHandler} as={Link} to="/react/expense-tracker">
                        Expense Tracker
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown bsPrefix="btn-link btn-nav" title="Animations" id="basic-nav-dropdown">
                      <NavDropdown.Item onClick={collapseNavHandler} as={Link} to="/404">
                        Animated 404 Page
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={collapseNavHandler} as={Link} to="/christmasCard">
                        Christmas Card
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown bsPrefix="btn-link btn-nav" title="Games" id="basic-nav-dropdown">
                      <NavDropdown.Item onClick={collapseNavHandler} as={Link} to="/games/oware">
                        Oware
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={collapseNavHandler} as={Link} to="/games/snake-variation">
                        Snake (variation)
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={collapseNavHandler} as={Link} to="/games/minesweeper">
                        Minesweeper
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown bsPrefix="btn-link btn-nav" title="Pokemon Apps" id="basic-nav-dropdown">
                      <NavDropdown.Item onClick={collapseNavHandler} as={Link} to="/pokeTypes">Pokemon Type Weaknesses/Resistances</NavDropdown.Item>
                      <NavDropdown.Item onClick={collapseNavHandler} as={Link} to="/pidgeyCalc">Pokemon GO! Pidgey XP Calc.</NavDropdown.Item>
                    </NavDropdown>
                    <div>
                      <Nav.Link onClick={collapseNavHandler} bsPrefix="btn-link btn-nav" as={Link} to="/about/aws-architecture">AWS Architecture</Nav.Link>
                    </div>
                    <div>
                      <Nav.Link bsPrefix="btn-link btn-nav" onClick={showArlisModal}>Arlis.org</Nav.Link>
                    </div>
                  </Nav>
                </Navbar.Collapse>
              </div>
            </div>
          </Navbar>
          <div id="content-wrapper">
            <div id="heading-banner-root" className="slideable">
                <div id="heading-banner__text" className="welcome-text-left">
                  <span>{appCtx.headingBanner}</span>
                </div>
            </div>

            <div id="quick-links">
              <span>
                <Link onClick={collapseNavHandler} role="button" className="btn btn-link" to="/about/aws-architecture">
                  <span className="quick-link-wrapper">
                    <span className="quick-link-icons">
                      <i className="fas fa-sitemap fa-2x mx-auto"></i>
                    </span>
                    AWS Architecture
                  </span>
                </Link>
                <Link onClick={collapseNavHandler} role="button" className="btn btn-link" to="/games/oware/">
                  <span className="quick-link-wrapper">
                    <span className="quick-link-icons">
                      <i className="fas fa-gamepad fa-2x mx-auto"></i>
                    </span>
                    Oware
                  </span>
                </Link>
                <Link
                  onClick={collapseNavHandler}
                  role="button"
                  className="btn btn-link"
                  to="/react/photo-gallery"
                >
                  <span className="quick-link-wrapper">
                    <span className="quick-link-icons">
                      <i className="fas fa-images fa-2x mx-auto"></i>
                    </span>
                    Photography Gallery
                  </span>
                </Link>
                <Link onClick={collapseNavHandler} role="button" className="btn btn-link" to="/react/expense-tracker">
                  <span className="quick-link-wrapper">
                    <span className="quick-link-icons">
                      <i className="fas fa-money-check-alt fa-2x mx-auto"></i>
                    </span>
                    Expense Tracker
                  </span>
                </Link>
                <Link onClick={collapseNavHandler} role="button" className="btn btn-link" to="/pokeTypes">
                  <span className="quick-link-wrapper">
                    <span className="quick-link-icons">
                      <i className="fas fa-leaf fa-2x mx-auto"></i>
                      <i className="fas fa-fire fa-2x mx-auto"></i>
                      <i className="fas fa-tint fa-2x mx-auto"></i>
                      <i className="fas fa-bolt fa-2x mx-auto"></i>
                    </span>
                    Pokemon Type Weaknesses/Resistances
                  </span>
                </Link>
              </span>
            </div>
          </div>
        </div>
      </header>
      <div className="mobile-header-bg"></div>
    </React.Fragment>
  );
};

export default Header;
