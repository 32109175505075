import React from 'react';
import styles from './ExpensesSelectionFilter.module.css';

function ExpensesSelectionFilter(props) {
  const filterChangeHandler = (event) => {
    props.onApplyFilter(event.target.value);
  };

  return (
    <div className={styles['expenses-filter']}>
      <div className={styles['expenses-filter__control']}>
        <label>{props.label}</label>
        <select value={props.selectedFilter} onChange={filterChangeHandler}>
            {props.children}
        </select>
      </div>
    </div>
  );
};

export default ExpensesSelectionFilter;
