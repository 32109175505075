import styles from './ExpenseTracker.module.css';
import React, { useState, useContext } from 'react';
import Expenses from './Expenses/Expenses/Expenses';
import NewExpense from './NewExpenseForm/NewExpense'
import Sidebar from '../../components/UI/Sidebar/Sidebar';
import Modal from '../../components/Modal/Modal';
import ModalsWrapper from '../../components/Modal/ModalsWrapper';
import { ExpenseFilterContext } from '../../store/ExpenseFilterContext';
import { AppContext } from '../../store/AppContext';
import DUMMY_EXPENSES from './dummy-data';

function ExpenseTracker() {
  const [expensesList, setExpensesList] = useState(DUMMY_EXPENSES);
  const [showNewExpenseModal, setShowNewExpenseModal] = useState(false);
  const expenseFilterCtx = useContext(ExpenseFilterContext);
  const appCtx = useContext(AppContext);

  const addExpenseHandler = (expense) => {
    setShowNewExpenseModal(false);
    setExpensesList((prevState) => {
      return [expense, ...prevState];
    });
    
    const categoryAlreadyAdded = (category) => {
      for(const expense of expensesList) {
        if(expense.category === category) {
          return true;
        }
      }
      return false;
    }

    const subcategoryAlreadyAdded = (subcategory) => {
      for(const expense of expensesList) {
        if(expense.subcategory === subcategory) {
          return true;
        }
      }
      return false;
    }

    if(!categoryAlreadyAdded(expense.category) && !expenseFilterCtx.categories.includes(expense.category)) {
      expenseFilterCtx.setCategoriesFilter(prevState => {
        return [
            ...prevState,
            expense.category
        ]
      })
    }
    if(!subcategoryAlreadyAdded(expense.subcategory) && !expenseFilterCtx.subcategories.includes(expense.subcategory)) {
      expenseFilterCtx.setSubcategoriesFilter(prevState => {
        return [
            ...prevState,
            expense.subcategory
        ]
      })
    }
  };

  const showExpenseModal = () => {
    setShowNewExpenseModal(true);
  };

  const hideExpenseModalHandler = () => {
    setShowNewExpenseModal(false);
  };

  return (
    <div className={styles['main']}>
      <Sidebar onAddExpense={showExpenseModal} />
      
      <Expenses items={expensesList} />

      <ModalsWrapper>
        {
          showNewExpenseModal ?
            <Modal onHideModal={hideExpenseModalHandler} darkMode={appCtx.darkMode}>
              <NewExpense 
                onAddExpense={addExpenseHandler}
                onHideModal={hideExpenseModalHandler}
              />
            </Modal>
            : ''
        }
      </ModalsWrapper>
    </div>
  );
}

export default ExpenseTracker;
