import React from 'react';
import styles from './CellsWrapper.module.css';
import Cells from '../Cell/Cell';

const CellsWrapper = (props) => {
  let cells = [];
  for(let index = 0; index < props.count; index++) {
    cells.push(<Cells cellIndex={index} key={`cell-${index}`} />)
  }
  return (
    <div className={styles.cellsWrapper}>{cells}</div>
  )
}

export default CellsWrapper