const SkillLogos = {
    python: require("../../static/images/python-logo-master-v3-TM.png").default,
    java: require("../../static/images/java-logo.png").default,
    javascript: require("../../static/images/JavaScript-logo.png").default,
    react: require("../../static/images/react-logo.png").default,
    django: require("../../static/images/django-logo-negative.png").default,
    cPlusPlus: require("../../static/images/cpp-logo.png").default,
    cLang: require("../../static/images/c-logo.png").default,
    jquery: require("../../static/images/jquery-logo.png").default,
    bootstrap: require("../../static/images/bootstrap-logo.jpeg").default,
    html5: require("../../static/images/html5-logo.png").default,
    css: require("../../static/images/css3-logo.png").default,
    less: require("../../static/images/less-logo.png").default,
    linux: require("../../static/images/linux-logo.png").default,
    git: require("../../static/images/git-logo.png").default,
    bash: require("../../static/images/bash-logo.png").default,
    mysql: require("../../static/images/mysql-logo.png").default,
    lua: require("../../static/images/lua-logo.png").default,
    agile: require("../../static/images/agile-logo.png").default,
    aws: require("../../static/images/aws-logo.png").default,
    objectiveC: require("../../static/images/objective-c-logo.png").default
}

export default SkillLogos;