import React from 'react';
import styles from './FoundationsWrapper.module.css';
import Foundation from '../Foundation/Foundation';

const FoundationsWrapper = (props) => {
  let foundations = [];
  for(let index = 0; index < props.count; index++) {
    foundations.push(<Foundation foundationIndex={index} key={`foundation-${index}`}/>)
  }
  return (
    <div className={styles.foundationsWrapper}>{foundations}</div>
  )
}

export default FoundationsWrapper