import ChartBar from './ChartBar';
import styles from './Chart.module.css';

function Chart(props) {
    const dataPointValues = props.dataPoints.map(dataPoint => dataPoint.value);
    const totalMaximum = Math.max(...dataPointValues);

    return (
        <div className={styles["chart"]}>
            {props.dataPoints.map(dataPoint => (
                <ChartBar 
                    key={dataPoint.label}
                    value={dataPoint.value} 
                    maxValue={totalMaximum}
                    label={dataPoint.label}
                />
    ))}
        </div>
    );
}

export default Chart;