import React, { useState, useContext } from 'react';
import styles from './Expenses.module.css';
import FiltersList from '../../ExpensesFilter/FiltersList/FiltersList';
import ExpensesList from '../ExpensesList/ExpensesList';
import ExpensesChart from '../ExpensesChart/ExpensesChart';
import { ExpenseFilterContext } from '../../../../store/ExpenseFilterContext';

function Expenses(props) {
  const ctx = useContext(ExpenseFilterContext);
  const [chartType, setChartType] = useState('year');

  const chartChangeHandler = (type) => {
    setChartType(type);
  };

  const categories = props.items.reduce((accumulatedCategories, currentExpense) => {
    if(!accumulatedCategories.includes(currentExpense.category)) {
      return [...accumulatedCategories, currentExpense.category];
    }
    else
      return accumulatedCategories;
  }, []);

  const subcategories = props.items.reduce((allSubcategories, currentExpense) => {
    if(!allSubcategories.includes(currentExpense.subcategory)) {
        return [
          ...allSubcategories, 
          {
            subcategory: currentExpense.subcategory, 
            disabled: !ctx.categories.includes(currentExpense.category)
          }
        ]
    }
    else
      return allSubcategories;
  }, []);

  let filteredExpenses = [];
  if(ctx.year === "All")
      filteredExpenses = props.items;
  else
      filteredExpenses = props.items.filter(expense => expense.date.getFullYear() == ctx.year);

  if(ctx.month !== 'All') {
    filteredExpenses = filteredExpenses.filter(expense => expense.date.getMonth() == ctx.month);
  }

  const disabledCategories = props.items.reduce((accumulator, current) => {
    if(!filteredExpenses.includes(current)) {
      accumulator = [...accumulator, current.category];
    }
    return accumulator;
  }, []);

  filteredExpenses = filteredExpenses.filter(expense => ctx.categories.includes(expense.category));

  const disabledSubcategories = props.items.reduce((accumulator, current) => {
    if(!filteredExpenses.includes(current)) {
      accumulator = [...accumulator, current.subcategory];
    }
    return accumulator;
  }, []);

  filteredExpenses = filteredExpenses.filter(expense => ctx.subcategories.includes(expense.subcategory));

  // Sort filteredExpenses
  const sortOrder = ctx.sortingOrder === 'Asc' ? 1 : -1;
  filteredExpenses = filteredExpenses.sort((first, second) => {
    if(ctx.sortingOption === 'Date') {
      if(first.date < second.date)
        return -1 * sortOrder;
      if(first.date > second.date)
        return sortOrder;
      return 0;
    }
    else if(ctx.sortingOption === 'Amount') {
      if(parseFloat(first.amount) > parseFloat(second.amount))
        return sortOrder;
      if(parseFloat(first.amount) < parseFloat(second.amount))
        return (-1 * sortOrder);
      return 0;
    }
    else { // ctx.sortOption === 'Credit'
      if(first.creditCard < second.creditCard)
        return -1 * sortOrder;
      if(first.creditCard > second.creditCard)
        return sortOrder;
      return 0;
    }
  })

  return (
    <div className={styles["expenses"]}>
        <FiltersList
          categories={categories}
          subcategories={subcategories}
          disabledCategories={disabledCategories}
          disabledSubcategories={disabledSubcategories}
          items={props.items}
          onChartChange={chartChangeHandler}
        />
        
        <div className={styles['expenses__data']}>
          <ExpensesChart expenses={filteredExpenses} chartType={chartType} />
          <ExpensesList expenses={filteredExpenses} />
        </div>
    </div>
  );
}

export default Expenses;
