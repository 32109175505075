import styles from './Sidebar.module.css';

import React from 'react'

const Sidebar = (props) => {
    

    return (
        <div className={styles['sidebar']}>
            <ul>
                <li>
                    <button onClick={props.onAddExpense}>Add New Expense</button>
                </li>
            </ul>
        </div>
    )
}

export default Sidebar
