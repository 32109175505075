import styles from './Modal.module.css';
import React from 'react';

const Modal = (props) => {
    let darkModeEnabled = props.darkMode ? "dark-mode" : '';
    let customCSS = props.className ? props.className : '';

    return (
        <React.Fragment>
            <div className={styles['backdrop']} onClick={props.onHideModal} />
            <div className={`${styles['modal']} ${darkModeEnabled} ${customCSS}`}>
                <div className={styles['modal-content']}>
                    {props.children}
                </div>
            </div>
        </React.Fragment>
    )
}

export default Modal
