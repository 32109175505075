import React, { useState } from "react";
import TabButton from "../../components/Buttons/TabButton/TabButton";
import { Container, Row, Col } from "react-bootstrap";
import TypeWeaknessTable from "./TypeWeaknessTable/TypeWeaknessTable";
import styles from './PokeTypeCalc.module.css';

const PokeTypeCalc = () => {
  const [firstType, setFirstType] = useState(null);
  const [secondType, setSecondType] = useState(null);
  const [typeSelectionError, setTypeSelectionError] = useState(null);

  const typeClickHandler = (typeValue) => {
    if (typeValue === firstType) {
      setFirstType(null);
      if(typeSelectionError) {
        setTypeSelectionError(null);
      }
    } else if (typeValue === secondType) {
      setSecondType(null);
      if(typeSelectionError) {
        setTypeSelectionError(null);
      }
    } else if (firstType && secondType) {
      setTypeSelectionError("Please only select at most 2 types.");
    } else {
      if (firstType) {
        setSecondType(typeValue);
        if(typeSelectionError) {
          setTypeSelectionError(null);
        }
      } else {
        setFirstType(typeValue);
        if(typeSelectionError) {
          setTypeSelectionError(null);
        }
      }
    }
  };

  const pokemonTypes = [
    "Normal",
    "Fighting",
    "Flying",
    "Poison",
    "Ground",
    "Rock",
    "Bug",
    "Ghost",
    "Steel",
    "Fire",
    "Water",
    "Grass",
    "Electric",
    "Psychic",
    "Ice",
    "Dragon",
    "Dark",
    "Fairy",
  ];

  let typeButtons = () => {
    let btns = [];
    for (const index in pokemonTypes) {
      let t = pokemonTypes[index];
      btns.push(
        <TabButton
          key={t}
          className={firstType === t || secondType === t ? "active" : ""}
          label={t}
          onClick={() => typeClickHandler(t)}
        />
      );
    }

    return btns;
  };

  let showError = typeSelectionError ? (
    <Container>
      <p className={styles.errorText}>{typeSelectionError}</p>
    </Container>
  ) : (
    ""
  );

  return (
    <Container id="TypeButtonsSection">
      <Row className="justify-content-center">
        <Col>
          <h3>Please choose one or two types to see weaknesses/resistances.</h3>
          {showError}
          <Row className={styles.TypeButtonsBox}>{typeButtons()}</Row>
          <TypeWeaknessTable pokemonTypes={pokemonTypes} first={pokemonTypes.findIndex((e) => e === firstType)} second={pokemonTypes.findIndex((e) => e === secondType)} />
        </Col>
      </Row>
    </Container>
  );
};

export default PokeTypeCalc;
