import React from 'react';
import styles from './TabButton.module.css';

const TabButton = (props) => {
    let customClasses = props.className ? props.className : '';

    return (
        <button onClick={props.onClick} className={`${styles.tabButton} ${customClasses}`}>
            {props.label}
        </button>
    )
}

export default TabButton
