import React, { useState } from 'react';

const AppContext = React.createContext({
    headingBanner: "",
    darkMode: false
  });

export const AppContextProvider = (props) => {
    const [headingBanner, setHeadingBanner] = useState('');
    const [darkMode, setDarkMode] = useState(false);

    return (
        <AppContext.Provider 
            value={{
                headingBanner: headingBanner,
                darkMode: darkMode,
                setHeadingBanner: setHeadingBanner,
                setDarkMode: setDarkMode
            }}
        >
            {props.children}
        </AppContext.Provider>
    )
}

export { AppContext };
