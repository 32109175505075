import React, { useState } from 'react';
import styles from './CategoryFilters.module.css';
import Category from './Category';

let DUMMMY_ACTIVE_CATEGORIES = ['Nature', 'Car', 'Music'];

const CategoryFilters = () => {
    const [showFilters, setShowFilters] = useState(false);

    const toggleFiltersHandler = event => {
        setShowFilters(!showFilters);
    }

    const categoryClickHandler = event => {
        // handle addition and removal of categories here
    }

    return (
        <div className={styles['category-filters']}>
            {
                showFilters ? 
                <div className={styles['categories']}>
                    <h3>Categories</h3>
                    <ul>
                        {
                            DUMMMY_ACTIVE_CATEGORIES.map(category => (
                                <li key={category}>
                                    <Category
                                        categoryName={category}
                                        clickable={true}
                                        onClick={categoryClickHandler}
                                    />
                                </li>
                            ))
                        }
                    </ul>
                </div> : ''
            }
            <div className={styles['category-filters__toggle']}>
                <button onClick={toggleFiltersHandler} className="custom-btn__primary">
                    {showFilters ? 'Hide' : 'Category Filters'}
                </button>
            </div>
            </div>
    )
}

export default CategoryFilters
