import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
import tree1 from "../../../static/images/christmas-tree1.svg";
import tree2 from "../../../static/images/christmas-tree2.svg";
import styles from "./FamilyChristmasCard.module.css";
import lights1 from "../../../static/images/christmas-lights-1.svg";
import lights2 from "../../../static/images/christmas-lights-2.svg";
import Gift from "../Gift/Gift";
import Modal from "../../../components/Modal/Modal";
import communityGiftCardImg1 from "../../../static/images/community-gift-card1.jpeg";
import communityGiftCardImg2 from "../../../static/images/community-gift-card2.jpeg";
import amazonGiftCardImg from "../../../static/images/amazon-gift-card.png";
import cottonSheetsImg from "../../../static/images/egyptian-cotton-sheets.jpg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import giftBow from "../../../static/images/gift-bow.svg";

const ChristmasCard = () => {
  const [showTagModal, setShowTagModal] = useState(null);
  const [showGiftModal, setShowGiftModal] = useState(null);
  const [openDadsGift, setOpenDadsGift] = useState(false);
  const [openMomsGift, setOpenMomsGift] = useState(false);
  const [openJuliesGift, setOpenJuliesGift] = useState(false);
  const { viewerId } = useParams();

  useEffect(() => {
    document.querySelector("header").style.display = "none";
    document.querySelector("footer").style.display = "none";
  }, []);

  const closeTagModal = () => {
    setShowTagModal(false);
  };

  const closeGiftModal = () => {
    setShowGiftModal(false);
  };

  const openGift = () => {
    if (showTagModal === "Dad") {
      setOpenDadsGift(true);
      setTimeout(() => setShowGiftModal("Dad"), 2000);
    } else if (showTagModal === "Mom") {
      setOpenMomsGift(true);
      setTimeout(() => setShowGiftModal("Mom"), 2000);
    } else {
      setOpenJuliesGift(true);
      setTimeout(() => setShowGiftModal("Julie"), 2000);
    }
    closeTagModal();
  };

  let giftTagModal = showTagModal ? (
    <Modal
      onHideModal={closeTagModal}
      className={styles.giftTag}
      darkMode={false}
    >
      <div className={styles.giftTagWrapper}>
        <div className={styles.giftTagBody}>
          <span>To: {showTagModal}</span>
          <span>Open this present?</span>
          <span>
            <PrimaryButton onClick={openGift} label="Yes" />
            <PrimaryButton onClick={closeTagModal} label="No" />
          </span>
        </div>
      </div>
    </Modal>
  ) : (
    ""
  );

  let giftDetails = null;

  if (showGiftModal === "Dad") {
    giftDetails = (
      <>
        <img src={amazonGiftCardImg} alt="gift card" />
        <span className={styles.giftCardText}>$100</span>
        <p>
          I've redeemed a $100 gift card on your Amazon account for you!
          <br />
          You should be able to use it as a payment option during checkout
          whenever you'd like.
        </p>
      </>
    );
  } else if (showGiftModal === "Mom") {
    giftDetails = (
      <>
        <img src={cottonSheetsImg} alt="Cotton sheets" />
        <p>I've sent you some king size sheets!</p>
      </>
    );
  } else {
    giftDetails = (
      <>
        <img src={communityGiftCardImg1} alt="" />
        <img src={communityGiftCardImg2} alt="" />
        <span className={styles.giftCardText}>$50</span>
        <p>
          I'll be sending you a "gift card" for a restaurant of your choice!
          <br />
          (As the reference to community implies, the "gift card" will be a cash
          transfer lol)
        </p>
      </>
    );
  }

  let giftPresentationModal = showGiftModal ? (
    <div className={styles.giftPresentationModalWrapper}>
      <Modal
        onHideModal={closeGiftModal}
        className={styles.giftPresentation}
        darkMode={false}
      >
        <div className={styles.giftBow}>
          <img src={giftBow} alt="gift bow" />
          <img src={giftBow} alt="gift bow" />
        </div>
        {giftDetails}
        <PrimaryButton onClick={closeGiftModal} label="Close" />
      </Modal>
    </div>
  ) : (
    ""
  );

  let dadsGift = (
    <div>
      {!openDadsGift && viewerId === "dad" ? (
        <span className={styles.clickMeText}>Click me</span>
      ) : (
        ""
      )}
      <Gift opened={openDadsGift} onClick={() => setShowTagModal("Dad")} />
    </div>
  );

  let momsGift = (
    <div>
      {!openMomsGift && viewerId === "mom" ? (
        <span className={styles.clickMeText}>Click me</span>
      ) : (
        ""
      )}
      <Gift opened={openMomsGift} onClick={() => setShowTagModal("Mom")} />
    </div>
  );

  let juliesGift = (
    <div>
      {!openJuliesGift && viewerId === "julie" ? (
        <span className={styles.clickMeText}>Click me</span>
      ) : (
        ""
      )}
      <Gift
        opened={openJuliesGift}
        onClick={() => setShowTagModal("Julie & Mike")}
      />
    </div>
  );

  return (
    <Container className={styles.cardWrapper}>
      <div className={styles.backdrop} />
      {giftTagModal}
      {giftPresentationModal}
      <Row>
        <Col>
          <div className={styles.merryChristmasText}>
            <svg className={styles.lgText}>
              <path id="curve1" d="M 20 150 Q 200 0 400 150" />
              <text>
                <textPath xlinkHref="#curve1">Merry Christmas!</textPath>
              </text>
            </svg>
            <svg className={styles.smText}>
              <path id="curve2" d="M 30 100 Q 130 0 260 100" />
              <text>
                <textPath xlinkHref="#curve2">Merry Christmas!</textPath>
              </text>
            </svg>
          </div>
          <div className={styles.christmasLights}>
            <img src={lights1} alt="Christmas Lights" />
            <img src={lights2} alt="Christmas Lights" />
          </div>
          <div className={styles.christmasTree}>
            <img src={tree1} alt="Christmas Tree" />
            <img src={tree2} alt="Christmas Tree" />
            <div className={styles.giftsWrapper}>
              {dadsGift}
              {momsGift}
              {juliesGift}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ChristmasCard;
