import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './FathersDayCard2022.module.css';
import img1 from '../../static/images/fathersDay2022/jimmy-cartoon-1.jpg';
import img2 from '../../static/images/fathersDay2022/jimmy-cartoon-2.jpg';
import img3 from '../../static/images/fathersDay2022/jimmy-cartoon-3.jpg';
import img4 from '../../static/images/fathersDay2022/jimmy-cartoon-4.jpg';
import img5 from '../../static/images/fathersDay2022/jimmy-cartoon-5.jpg';
import img6 from '../../static/images/fathersDay2022/jimmy-cartoon-6.jpg';
import img7 from '../../static/images/fathersDay2022/jimmy-cartoon-7.jpg';
import img8 from '../../static/images/fathersDay2022/jimmy-cartoon-8.jpg';
import img9 from '../../static/images/fathersDay2022/jimmy-cartoon-9.jpg';
import img10 from '../../static/images/fathersDay2022/jimmy-cartoon-10.jpg';

const FathersDayCard2022 = () => {
  return (
    <div className={styles.cardWrapper}>
        <h1>Happy Father's Day!</h1>
        <p>Please enjoy this animated adaptation of this classic short poem.</p>
        <div>
            <span>My Name is Jimmy</span>
            <div className={styles.animationWrapper}>
                <div className={styles.animationPanel}>
                    <img src={img1} className={styles.fourPanelFirst} />
                    <img src={img2} className={styles.fourPanelSecond} />
                    <img src={img3} className={styles.fourPanelThird} />
                    <img src={img4} className={styles.fourPanelFourth} />
                </div>
            </div>
            <span>I live by the sea</span>
            <div className={styles.animationWrapper}>
                <div className={styles.animationPanel}>
                    <img src={img5} className={styles.twoPanelFirst} />
                    <img src={img6} className={styles.twoPanelSecond} />
                </div>
            </div>
            <span>I like to run around naked, <br/> as you can plainly see</span>
            <div className={styles.animationWrapper}>
                <div className={styles.animationPanel}>
                    <img src={img7} className={styles.fourPanelFirst} />
                    <img src={img8} className={styles.fourPanelSecond} />
                    <img src={img9} className={styles.fourPanelThird} />
                    <img src={img10} className={styles.fourPanelFourth} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default FathersDayCard2022