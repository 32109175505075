import { useState } from 'react';

const useExpenseInput = (validationFunction) => {
    const [inputValue, setInputValue] = useState('');
    const [isTouched, setIsTouched] = useState(false);

    const isValid = validationFunction(inputValue);
    const hasError = !isValid && isTouched;

    const changeHandler = (event) => {
        setInputValue(event.target.value);
    }

    const blurHandler = (event) => {
        setIsTouched(true);
    }

    const reset = () => {
        setInputValue('');
        setIsTouched(false);
    }

    return {
        value: inputValue,
        isValid,
        hasError,
        changeHandler,
        blurHandler,
        setIsTouched,
        reset
    }
}

export default useExpenseInput;