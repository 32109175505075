import React from 'react';
import styles from './GalleryOptions.module.css'
import SearchBar from '../../../components/UI/SearchBar/SearchBar';
import LayoutToggle from '../LayoutToggle/LayoutToggle';
import CategoryFilters from '../CategoryFilters/CategoryFilters';

const GalleryOptions = () => {
    return (
        <div className={styles['gallery-options']}>
            <div className={styles['gallery-options__row']}>
                <SearchBar />
                <LayoutToggle />
            </div>
            <CategoryFilters />
        </div>
    )
}

export default GalleryOptions
