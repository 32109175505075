import Chart from '../../../../components/Chart/Chart';

function ExpensesChart(props) {
    let chartDataPoints;
    if(props.chartType === 'year') {
        chartDataPoints = [
            { label: 'Jan', value: 0 },
            { label: 'Feb', value: 0 },
            { label: 'Mar', value: 0 },
            { label: 'Apr', value: 0 },
            { label: 'May', value: 0 },
            { label: 'Jun', value: 0 },
            { label: 'Jul', value: 0 },
            { label: 'Aug', value: 0 },
            { label: 'Sep', value: 0 },
            { label: 'Oct', value: 0 },
            { label: 'Nov', value: 0 },
            { label: 'Dec', value: 0 },
        ];

        for(const expense of props.expenses) {
            const expenseMonth = expense.date.getMonth(); // Months start at 0
            chartDataPoints[expenseMonth].value += expense.amount;
        }
    }
    else if(props.chartType === 'month') {
        let chartMonthDataPoints = [];
        for(let i = 1; i < 32; i++) {
            chartMonthDataPoints.push('' + i);
        }
        chartDataPoints = chartMonthDataPoints.map(dp => {
            return { label: dp, value: 0 };
        })

        for(const expense of props.expenses) {
            const expenseDay = expense.date.getDate() - 1; // Months start at 0
            chartDataPoints[expenseDay].value += expense.amount;
        }
    }
    
    return (
        <Chart dataPoints={chartDataPoints} />
    );
}

export default ExpensesChart;