import react, { useState } from 'react';

const PhotoGalleryLayoutContext = react.createContext({
    layoutType: 'list',
})

export const PhotoGalleryLayoutContextProvider = (props) => {
    const [layoutType, setLayoutType] = useState('List');

    return (
        <PhotoGalleryLayoutContext.Provider 
            value={{
                layoutType: layoutType,
                setLayoutType: setLayoutType,
            }}
        >
            {props.children}
        </PhotoGalleryLayoutContext.Provider>
    )
}

export { PhotoGalleryLayoutContext };