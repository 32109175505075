import React, { useReducer, useState } from 'react';
import styles from './ExpenseForm.module.css';
import useExpenseInput from '../../../hooks/useExpenseInput/useExpenseInput';

const cashBackReducer = (state, action) => {
  return { value: action.val, show: action.show }
}

function ExpenseForm(props) {
  const textInputValidation = value => value.trim() !== '';
  const currencyInputValidation = value => value >= 0.01;

  const {
    value: titleValue,
    isValid: titleIsValid,
    hasError: titleHasError,
    changeHandler: titleChangeHandler,
    blurHandler: titleBlurHandler,
    setIsTouched: setTitleIsTouched,
    reset: resetTitle
  } = useExpenseInput(textInputValidation)

  const {
    value: amountValue,
    isValid: amountIsValid,
    hasError: amountHasError,
    changeHandler: amountChangeHandler,
    blurHandler: amountBlurHandler,
    setIsTouched: setAmountIsTouched,
    reset: resetAmount
  } = useExpenseInput(currencyInputValidation)

  const {
    value: dateValue,
    isValid: dateIsValid,
    hasError: dateHasError,
    changeHandler: dateChangeHandler,
    blurHandler: dateBlurHandler,
    setIsTouched: setDateIsTouched,
    reset: resetDate
  } = useExpenseInput(textInputValidation)

  const {
    value: categoryValue,
    isValid: categoryIsValid,
    hasError: categoryHasError,
    changeHandler: categoryChangeHandler,
    blurHandler: categoryBlurHandler,
    setIsTouched: setCategoryIsTouched,
    reset: resetCategory
  } = useExpenseInput(textInputValidation)

  const {
    value: subcategoryValue,
    isValid: subcategoryIsValid,
    hasError: subcategoryHasError,
    changeHandler: subcategoryChangeHandler,
    blurHandler: subcategoryBlurHandler,
    setIsTouched: setSubcategoryIsTouched,
    reset: resetSubcategory
  } = useExpenseInput(textInputValidation)

  const [creditCard, setCreditCard] = useState('N/A');

  const [cashBackState, dispatchCashBack] = useReducer(cashBackReducer, {
    value: 'N/A',
    show: false
  });

  const clearForm = () => {
    resetTitle();
    resetAmount();
    resetDate();
    resetCategory();
    resetSubcategory();
  };

  const creditCardChangeHandler = (event) => {
    setCreditCard(event.target.value)

    if(creditCard !== 'N/A') {
      dispatchCashBack({
        val: 'N/A',
        show: false
      });
    } 
    else { 
      dispatchCashBack({
        val: 'N/A',
        show: true
      });
    }
  };

  const cashBackChangeHandler = (event) => {
    dispatchCashBack({
      val: event.target.value,
      show: true
    })
  };

  const validFormInput = () => {  
    return titleIsValid && amountIsValid && dateIsValid && categoryIsValid && subcategoryIsValid;
  };

  const hideFormHandler = (event) => {
    props.onHideForm();
  };

  const submitHandler = (event) => {
    event.preventDefault();

    setTitleIsTouched(true);
    setAmountIsTouched(true);
    setDateIsTouched(true);
    setCategoryIsTouched(true);
    setSubcategoryIsTouched(true);

    if(!validFormInput()) {
      return;
    }
    const expenseData = {
      title: titleValue,
      amount: +amountValue,
      date: new Date(dateValue + 'T00:00:00'),
      category: categoryValue,
      subcategory: subcategoryValue,
      creditCard: creditCard,
      cashBack: cashBackState.value
    };

    props.onSaveExpenseData(expenseData);
    clearForm();
  };

  return (
    <form onSubmit={submitHandler}>
      <div className={styles["new-expense__controls"]}>
        <div className={styles["new-expense__control"]}>
          <label>Title</label>
          <input
            className={titleHasError ? styles['invalid-input'] : ''}
            type="text"
            value={titleValue}
            onChange={titleChangeHandler}
            onBlur={titleBlurHandler}
          />
          {titleHasError ? <p>Title is required</p> : ''}
        </div>
        <div className={styles["new-expense__control"]}>
          <label>Amount</label>
          <input
            className={amountHasError ? styles['invalid-input'] : ''}
            type="number"
            min="0.01"
            step="0.01"
            value={amountValue}
            onChange={amountChangeHandler}
            onBlur={amountBlurHandler}
          />
          {amountHasError ? <p>Amount is required</p> : ''}
        </div>
        <div className={styles["new-expense__control"]}>
          <label>Date</label>
          <input
            className={dateHasError ? styles['invalid-input'] : ''}
            type="date"
            min="2019-01-01"
            max="2022-12-31"
            onChange={dateChangeHandler}
            onBlur={dateBlurHandler}
            value={dateValue}
          />
          {dateHasError ? <p>Date is required</p> : ''}
        </div>
        <div className={styles["new-expense__control"]}>
          <label>Category</label>
          <input
            className={categoryHasError ? styles['invalid-input'] : ''}
            type="text"
            onChange={categoryChangeHandler}
            onBlur={categoryBlurHandler}
            value={categoryValue}
          />
          {categoryHasError ? <p>Category is required</p> : ''}
        </div>
        <div className={styles["new-expense__control"]}>
          <label>Subcategory</label>
          <input
            className={subcategoryHasError ? styles['invalid-input'] : ''}
            type="text"
            onChange={subcategoryChangeHandler}
            onBlur={subcategoryBlurHandler}
            value={subcategoryValue}
          />
          {subcategoryHasError ? <p>Subcategory is required</p> : ''}
        </div>
        <div className={styles["new-expense__control"]}>
          <label>Credit Card</label>
          <select
            onChange={creditCardChangeHandler}
            className={styles['select']}
            value={creditCard}
          >
            <option value='N/A'>N/A</option>
            <option value='Amex 1023'>Amex 1023</option>
          </select>
        </div>
        <div 
          className={
            `${styles["new-expense__control"]} 
            ${cashBackState.show ? '' : styles["hidden"]}`
          }
        >
          <label>Cash Back %</label>
          <select 
            onChange={cashBackChangeHandler}
            className={styles['select']}
            value={cashBackState.value}
          >
            <option value="N/A">N/A</option>
            <option value="2%">2%</option>
          </select>
        </div>
        <div className={`${styles["new-expense__control"]} ${styles["description-textarea"]}`}>
            <label>Description</label>
            <textarea className={styles['description-textarea__input']}></textarea>
        </div>
      </div>
      <div className={styles["new-expense__actions"]}>
        <button type="button" onClick={hideFormHandler}>Cancel</button>
        <button type="submit">Add Expense</button>
      </div>
    </form>
  );
}

export default ExpenseForm;
