import React from 'react'
import reactDom from 'react-dom';

const modalRoot = document.getElementById('modal-root');

const ModalsWrapper = (props) => {
    return (
        reactDom.createPortal(<React.Fragment>{props.children}</React.Fragment>, modalRoot)
    );
}

export default ModalsWrapper