import ExpenseItem from '../ExpenseItem/ExpenseItem';
import styles from './ExpensesList.module.css';

function ExpensesList(props) {
  let expensesContent = (
      <li>
        <p className="expenses-list__fallback">No content to display.</p>
      </li>
    );

  if(props.expenses.length > 0) {
    expensesContent = props.expenses.map(expense => (
      <ExpenseItem
        key={expense.id}
        title={expense.title}
        amount={expense.amount}
        date={expense.date}
        category={expense.category}
        subcategory={expense.subcategory}
        creditCard={expense.creditCard}
        cashBack={expense.cashBack}
      />
    ));
  };

  return (
    <ul className={styles["expenses-list"]}>
      {expensesContent}
    </ul>
  );
}

export default ExpensesList;
