import ExpenseDate from '../ExpenseDate/ExpenseDate';
import Card from '../../../../components/UI/Card/Card';
import styles from './ExpenseItem.module.css';

function ExpenseItem(props) {
  return (
    <li>
      <Card className={styles['expense-item']}>
        <ExpenseDate date={props.date} />
        <div className={styles['expense-item__description']}>
          <div className={styles['expense-item__content']}>
            <h2>{props.title}</h2>
            <span className={styles['expense-item__category']}>
              {props.category}
            </span>
            <span className={styles['expense-item__subcategory']}>
              {props.subcategory}
            </span>
          </div>
          <div className={styles['expense-item__price-block']}>
            {
              props.creditCard === 'N/A' ? '' : 
              <div className={styles['expense-item__credit']}>
                {props.creditCard}
              </div>
            }
            <div className={styles['expense-item__price']}>${props.amount.toFixed(2)}</div>
            {
              props.cashBack === 'N/A' ? '' :
              <div className={styles['expense-item__cash-back']}>
                - ${(parseInt(props.cashBack.replace('%', '')) * .01 * props.amount).toFixed(2)}
                 &nbsp;/ {props.cashBack}
              </div>
            }
          </div>
        </div>
      </Card>
    </li>
  );
}

export default ExpenseItem;
