import React, { useState } from 'react';
import Gallery from './Gallery/Gallery';
import Modal from '../../components/Modal/Modal';
import ModalsWrapper from '../../components/Modal/ModalsWrapper';
import { PhotoGalleryLayoutContextProvider } from '../../store/PhotoGalleryLayoutContext';

const PhotoGallery = () => {
  const [showIntro, setShowIntro] = useState(true);

  const hideIntro = () => {
    setShowIntro(false);
  }

  return (
    <React.Fragment>
      <ModalsWrapper>
        { 
          showIntro ? 
          <Modal onHideModal={hideIntro} darkMode={true}>
            <div className="intro-content">
              <p>
                This is a demo of a photo gallery app I am working on. <br/> All of the photos presented here were personally taken by myself, <br/>please do not reuse
                them without permission. <br/> The search and category filters are not functional yet, but I will be working on implementing these features!
              </p>
              <button onClick={hideIntro} className="custom-btn__primary">Okay</button>
            </div>
          </Modal> : '' 
        }
      </ModalsWrapper>
      <div className="PhotoGalleryApp">
        <span className="bg-cross-left"></span>
        <span className="bg-cross-right"></span>
        <PhotoGalleryLayoutContextProvider>
          <Gallery />
        </PhotoGalleryLayoutContextProvider>
      </div>
    </React.Fragment>
  );
}

export default PhotoGallery;