import SkillBadge from "./SkillBadge";
import SkillLogos from "./SkillLogos";

const SkillsInfo = () => {
      return(
        <div id="skills-info" style={{position:"relative"}}>
          <span id="skills-anchor" className="anchor"></span>
          <div className="heading">
            <h1>Skills</h1>
            <h5>Click on an image below to view a description of my experience with that skill</h5>
          </div>
          <div className="badges-row">
            <SkillBadge 
              id="python-badge" 
              src={SkillLogos.python} 
              description="I have 2 years of experience working in this language 
              academically and almost 2 years of professional experience. I have developed 
              some basic client and server programs in python which perform simple 
              tasks such as file transfer protocol. I also have some experience 
              developing basic machine learning programs which implement neural 
              net designs, perform k-means clustering, principal component 
              analysis, and natural language processing. I have also developed several websites (including this one) 
              using python and more specifically the Django framework." 
            />
            <SkillBadge 
              id="java-badge" 
              src={SkillLogos.java} 
              description={
                <span>
                  I have 3 years of experience working in this language academically. 
                  Other than Python, this is probably the language I am most familiar with. I have developed 
                  several programs in Java that demonstrate artifical intelligence 
                  algorithms. I have implemented constraint satisfaction to determine 
                  the solution to a given sudoku puzzle, if one exists; a demonstration 
                  of the traveling salesman problem that calculates the cost of the 
                  shortest path connecting every node in a fully connected graph using 
                  hill climbing with random restart as well as with simulated annealing 
                  for comparison; the algorithm used to calculate the computer move for 
                  oware on this site was originally from a program I wrote in Java which 
                  implemented min-max with alpha-beta pruning. A Java version of the 
                  Pokemon Go Pidgey Calc. on this site, as well as all the programs 
                  described here, can be found on my 
                  <a target='_blank' rel="noreferrer" href='https://www.github.com/nnmcdonald'>github</a>
                </span>
              } 
            />
            <SkillBadge 
              id="js-badge" 
              src={SkillLogos.javascript} 
              description="I have almost 3 years of experience with this language. 
              I work with javascript daily in my current role, primarily within the React
              framework as well as within marketing platforms such as Google Ads
              and Tealium IQ. This site also uses it in 
              the operation of the apps and games."
            />
            <SkillBadge 
              id="react-badge" 
              src={SkillLogos.react}
              description={
                <span>
                  I have over one year of experience working with React in my personal projects
                  and currently use it for development in my current Software Engineering role
                  with LeadVenture. 
                  I have recently fully converted this site to a React project 
                  so that I may utilize a serverless architecture on the backend.
                  Most of the content was migrated from the original Django implemntation, however 
                  you may view my projects which were specifically designed as React apps in 
                  the React Apps drop down menu in the navbar.
                </span>
              } 
            />
            <SkillBadge 
              id="django-badge" 
              src={SkillLogos.django} 
              description="I have developed several websites in the Django framework. 
              I created a completely new site using Django for the Alaska Resources Library 
              and Information Services. For my capstone project I developed a website in 
              Django that allowed users to register and take a color vision test. 
              The purpose of the project was to save patients and doctors time by 
              eliminating the need for a physical visit to take a simple 10 minute test.
              Additionally, this portfolio site was initially designed using Django as well." 
            />
            <SkillBadge 
              id="aws-badge" 
              src={SkillLogos.aws}
              description="I have almost 2 years of experience working with AWS. This 
              website was initially launched using Elastic Beanstalk, and has since been redesigned
              to leverage serverless architecture using CloudFront, S3, API Gateway, and Lambda.
              I also work with AWS in my current Software Engineering role with LeadVenture." 
            />
            <SkillBadge 
              id="cpp-badge" 
              src={SkillLogos.cPlusPlus}
              description="I have 2 years of experience in c++ academically. The Pokemon Go 
              Pidgey Calc. on This site was originally wriiten in c++, which can be found on 
              my github. I am also currently developing an iOS game utilizing c++ and c#." 
            />
            <SkillBadge 
              id="c-badge" 
              src={SkillLogos.cLang}
              description={
                <span>
                  My college course on operating systems was taught primarily through 
                  the use of C. As part of the coursework I developed programs in C which demonstrate 
                  the use of Mutex to solve the Dining Philosophers problem 
                  (<a target="_blank" rel="noreferrer" href="https://en.wikipedia.org/wiki/Dining_philosophers_problem">
                    wikipedia Dining philosophers problem</a>), 
                  and another which customizes the bash shell on an Ubuntu machine.
                </span>
              } 
            />
            <SkillBadge 
              id="mysql-badge" 
              src={SkillLogos.mysql}
              description="My databases course at UAA focused on MySQL. As part of the coursework 
              I designed a basic database with documentation and a simple front-end web interface 
              using PHP and HTML templates. In my work with ARLIS I also restructered their 
              existing MySQL database to eliminate unneccessary data and connected it to the Django 
              project. I also work with MySQL in my current role with LeadVenture." 
            />
            <SkillBadge 
              id="jquery-badge" 
              src={SkillLogos.jquery}
              description="I have over 2 years of experience with jQuery. I used the 
              jQuery library on a daily basis in my previous role with Dealerspike in conjunction with vanilla 
              javascript to create scripts for tasks such as generating form content dynamically 
              and patching erroneous search functionality." 
            />
            <SkillBadge 
              id="bootstrap-badge" 
              src={SkillLogos.bootstrap}
              description={
                <span>
                  I have used the Bootstrap library in most of the web development projects 
                  and jobs I have worked on. In my work on 
                  <a target="_blank" rel="noreferrer" href="www.arlis.org">ARLIS.org</a> and 
                  my capstone project I utilized Bootstrap for most of the front end design. 
                  In my current role with LeadVenture, and with this site since fully redesigning 
                  it site with React, I have utilized the 'react-bootstrap' library.  
                </span>
              } 
            />
            <SkillBadge 
              id="html5-badge" 
              src={SkillLogos.html5} 
              description="I have over 2 years of experience designing custom HTML templates 
              professionally. In my previous role with Dealerspike I assisted clients with 
              HTML updates and maintenance for their websites." 
            />
            <SkillBadge 
              id="css3-badge" 
              src={SkillLogos.css}
              description="I have over 2 years of experience creating custom css designs. 
              In my previous role with Dealerspike I mostly used the less precompiler for 
              style sheets, however it was still be useful occassionally to use internal css on pages when 
              creating mockups for clients." 
            />
            <SkillBadge 
              id="less-badge" 
              src={SkillLogos.less}
              description="I have over one year of experience with less.
              In my previous role with Dealerspike I used less on a daily 
              basis when creating or modifying content for clients." 
            />
            <SkillBadge 
              id="agile-badge" 
              src={SkillLogos.agile}
              description="In my work for ARLIS I adhered to an Agile process working in 1-2 
              week sprints. In my current role, the team I work with adheres to the Scrum framework." 
            />
            <SkillBadge 
              id="linux-badge" 
              src={SkillLogos.linux}
              description="I have several years of experience using Ubuntu, and some experience 
              with centOS in the development of the ARLIS site." 
            />
            <SkillBadge 
              id="git-badge" 
              src={SkillLogos.git}
              description={
                <span>
                  I have used git in my personal projects and professional work for several years. 
                  Most of the projects I have worked on that utilized git can be seen on 
                  <a target="_blank" rel="noreferrer" href="https://www.github.com/nnmcdonald">github</a>.
                </span>
              }  
            />
            <SkillBadge 
              id="bash-badge" 
              src={SkillLogos.bash} 
              description="I used Ubuntu as my main operating system for development for 
              several years and used the bash shell regularly. As part of my coursework for 
              my Operating Systems course I created a shell script and a program in C which 
              modified the bash shell and its functionality. I still use the bash shell regularly 
              on my Mac for the purposes of testing and developing this portfolio site." 
            />
            <SkillBadge 
              id="lua-badge" 
              src={SkillLogos.lua}
              description="As part of the coursework for a Programming Language Concepts course 
              I used Lua to create an interpreter for a scripted language designed by the 
              professor which he named Dugong. The interpreter design consisted of three 
              programs developed in Lua throughout the semester. I designed a Lexer to convert 
              command line entries or program files to lexemes; the Lexer output was then used 
              by a Parser program which parses the lexemes for errors and generates an 
              Abstract Syntax Tree; the third program was the Interpreter which could be 
              invoked with Lua to execute Dugong code, using the AST generated by the Parser, 
              in the command line, or used to interpret saved files with the .du extension. " 
            />
            <SkillBadge 
              id="c-sharp-badge" 
              src={SkillLogos.objectiveC}
              description="I have less than a year of experience with Objective-C. 
              I am currently developing an iOS game utilizing c++ and Objective-C." 
            />
          </div>
        </div>
      );
    }

  export default SkillsInfo;