import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import swipedetect from "../../utilities/touchSwipeDetect";
import styles from './SnakePhoneVariation.module.css';

const SnakePhoneVariation = () => {
  const [newGameCounter, setNewGameCounter] = useState(0);

  useEffect(() => {
    let game_interval = null;

    let canv=document.getElementById("gc");
    let ctx=canv.getContext("2d");
    document.addEventListener("keydown",keyPush);
    swipedetect(canv, function(swipedir){
        // swipedir contains either "none", "left", "right", "up", or "down"
        keyPush(swipedir);
    });
    ctx.fillStyle="white";
    ctx.font = "25px Arial";
    ctx.fillText("Press any button to start",40,180);

    let phoneNum = "(";
    // Player coordinates, head of snake
    let px=10;
    let py=10;
    // grid square width
    let gs=18;
    // grid column/row count
    let tc=20;
    
    let goals = [];
    let coordsInUse = [];
    
    for(let i = 0; i < tc; i++) {
        coordsInUse.push([]);
        for(let j = 0; j < tc; j++) {
            coordsInUse[i][j] = false;
        };
    };
    coordsInUse[15][15] = true;
    
    // Randomly place the goal squares
    // Won't place goal squares on border initially
    for(let i = 0; i < 10; i++) {
        goals[i] = {x:null,y:null};
        do {
            goals[i].x=Math.floor(1+Math.random()*(tc-2));
            goals[i].y=Math.floor(1+Math.random()*(tc-2));
        } while(coordsInUse[goals[i].x][goals[i].y]);
        coordsInUse[goals[i].x][goals[i].y] = true;
    }
    
    let xv=0;
    let yv=0;
    let trail=[];
    let tail = 5;
    
    function gameOver(error) {
        clearInterval(game_interval);
        document.getElementById("try-again-block").style.display = "block";
        if(error) {
            document.getElementById("crash").style.display = "block";
        }
    }
    
    function game() {
        px+=xv;
        py+=yv;
        if(px<0 || px>tc-1 || py<0 || py>tc-1) {
            gameOver(true);
        }
    
        // game area
        ctx.fillStyle="black";
        ctx.fillRect(0,0,canv.width,canv.height);
    
        // snake squares
        ctx.fillStyle="green";
        for(let i=0;i<trail.length;i++) {
            ctx.fillRect(trail[i].x*gs,trail[i].y*gs,gs-2,gs-2);
            if(trail[i].x===px && trail[i].y===py) {
                gameOver(true);
            }
        }
    
        trail.push({x:px,y:py});
        try {
            coordsInUse[px][py] = true;
        } catch(err) {
            if (err instanceof TypeError) {
                gameOver(true);
            }
        }
        
        while(trail.length>tail) {
            coordsInUse[trail[0].x][trail[0].y] = false;
            trail.shift();
        }
    
        // Draw goal squares
        for(let i = 0; i < goals.length; i ++) {
            // Check for goal square capture
            if(goals[i].x===px && goals[i].y===py) {
                tail++;
                phoneNum += "" + i;
                if(phoneNum.length === 4) {
                    phoneNum += ") ";
                } else if(phoneNum.length === 9) {
                    phoneNum += " ";
                };
                document.getElementById("phone").innerHTML = phoneNum;
                // end the game when all numbers are collected
                if(phoneNum.length > 13) {
                    gameOver();
                };
                // Move goal square to a new random location
                let currX = goals[i].x;
                let currY = goals[i].y;
                do {
                    goals[i].x=Math.floor(Math.random()*tc);
                    goals[i].y=Math.floor(Math.random()*tc);
                } while(coordsInUse[goals[i].x][goals[i].y]);
                coordsInUse[currX][currY] = false;
                coordsInUse[goals[i].x][goals[i].y] = true;
            }
            //ctx.fillStyle="black";
            //ctx.fillRect(goals[i].x*gs,goals[i].y*gs,gs-2,gs-2);
            ctx.fillStyle="white";
            ctx.font = "15px Arial";
            ctx.fillText(("" + i),goals[i].x*gs+6,goals[i].y*gs+14);
        }
    }
    
    function getMove(evt, firstMove) {
        let secondInTrail = (firstMove ? {x:null,y:null}:trail[trail.length-2])
        if((evt.keyCode===37) || (evt==="left")) {
                if(secondInTrail.x !== (px-1) && secondInTrail.y !== (py)) {
                    xv=-1;yv=0;
                }
        }else if(evt.keyCode===38 || evt==="up"){
            if(secondInTrail.x !== (px) && secondInTrail.y !== (py-1)) {
                xv=0;yv=-1;
            }
        }else if(evt.keyCode===39 || evt==="right"){
            if(secondInTrail.x !== (px+1) && secondInTrail.y !== (py)) {
                xv=1;yv=0;
            }
        } else if(evt.keyCode===40 || evt==="down"){
            if(secondInTrail.x !== (px) && secondInTrail.y !== (py+1)) {
                xv=0;yv=1;
            }
        } else if(firstMove) {
                xv=0;yv=1;
        };
    }
    
    function keyPush(evt) {
        let first = false;
        if([32, 37, 38, 39, 40].indexOf(evt.keyCode) > -1) {
            evt.preventDefault();
      }
        if(!game_interval) {
            first = true;
            game_interval = setInterval(game,1000/10);
            document.getElementById("your-phone-text").style.display = "block";
        }
        getMove(evt, first);
    }
      return () => {
        document.removeEventListener("keydown",keyPush);
      }
  }, [newGameCounter]);
  
  const startNewGame = () => {
    document.getElementById("phone").innerHTML = '';
    document.getElementById("crash").style.display = "none";
    document.getElementById("try-again-block").style.display = "none";
    setNewGameCounter((prev) => prev + 1);
  }

  return (
    <Container>
      <div className={styles["game-wrapper"]}>
        
        <div id="game-description" className={styles.desc}>
        <h1>Snake Phone Input</h1>
        <h2>Instructions</h2>
        <p>
          In this variation of the game Snake you must "eat" numbers to form a phone number, 
          and if you hit the walls you crash! Try to form a specific phone
          number, if you can.
        </p>
        <h2>Why a phone number?</h2>
        <p>
          I designed this variation of snake for a coding challenge posed by one of
          my coworkers at Dealer Spike. The purpose of the challenge was to create 
          a terrible, yet functional, means of inputing a phone number. 
          Usually developers do not try to design bad UI but it happens, so this challenge 
          was an amusing way to see how bad it could be if the goal was to design 
          a difficult UI/UX. Just imagine trying to enter a phone number on a form only
          using Snake!
        </p>
      </div>
        <canvas id="gc" className={styles.gc} width="360" height="360"></canvas>
        <div className={styles.gameUI}>
          <h3 id="your-phone-text" style={{display:"none"}}>
            Your Phone Number: <br className="mobile-br" />
            <span id="phone"></span>
          </h3>
          <div id="try-again-block" className={styles["try-again-block"]} style={{display:"none"}}>
            <h4 id="crash" className={styles.crashText}>
              You Crashed!
            </h4>
            <button 
                className="btn btn-primary"
                onClick={startNewGame}>
              Try Again
            </button>
          </div>
        </div>
      </div>
      
    </Container>
  );
};

export default SnakePhoneVariation;
