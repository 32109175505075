import React from "react";
import styles from "./Gift.module.css";
import confettiImg from '../../../static/images/confetti.svg';

const Gift = (props) => {
  const giftClickHandler = () => {
    if(!props.opened) {
      props.onClick();
    }
  }

  let confetti = props.opened ? (
    <span className={styles.confettiWrapper}>
      <img src={confettiImg} alt="Confetti" />
    </span>
  ) : '';

  let faIcon = props.opened ? (
    <i class="fas fa-box-open fa-5x"></i>
  ) : (
    <i class="fas fa-gift fa-5x"></i>
  );

  let giftStyle = props.opened ? styles.opened : styles.closed;

  return (
    <div className={`${styles.giftWrapper} ${giftStyle}`} onClick={giftClickHandler}>
        <span className={styles.giftBg}></span>
        {faIcon}
        {confetti}
    </div>
  );
};

export default Gift;
