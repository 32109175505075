import styles from './FiltersList.module.css';
import ExpensesSelectionFilter from '../ExpenseSelectionFilter/ExpensesSelectionFilter';
import ExpensesCheckboxFilter from '../ExpenseCheckboxFilter/ExpensesCheckboxFilter';
import React, { useState, useContext } from 'react'
import { ExpenseFilterContext } from '../../../../store/ExpenseFilterContext';

const FiltersList = (props) => {
    const ctx = useContext(ExpenseFilterContext);
    const [expanded, setExpanded] = useState(false);

    const filterYearHandler = (filter) => {
        if(filter === 'All') {
            ctx.setMonthFilter('All');
            props.onChartChange('year');
        }
        ctx.setYearFilter(filter);
    }

    const filterMonthHandler = (filter) => {
        if(filter === 'All') {
            props.onChartChange('year');
        }
        else
            props.onChartChange('month');
        ctx.setMonthFilter(filter);
    }

    const filterCategoriesHandler = (filters) => {
        ctx.setCategoriesFilter(filters);
    }

    const filterSubcategoriesHandler = (filters) => {
        ctx.setSubcategoriesFilter(filters);
    }

    const sortingOptionHandler = (sortBy) => {
        ctx.setSortingOption(sortBy);
    };

    const sortingOrderHandler = (order) => {
        ctx.setSortingOrder(order);
    };

    const subcategoryCheckboxChangeHandler = (isChecked, filter) => {
        if(isChecked) {
            ctx.setSubcategoriesFilter(prevState => {
                return [
                    ...prevState,
                    filter
                ]
            })
        } else {
            ctx.setSubcategoriesFilter(prevState => {
                let temp = [...prevState];
                let index = temp.indexOf(filter);
                if (index !== -1) {
                    temp.splice(index, 1);
                }
                ctx.setSubcategoriesFilter(temp);
            })
        }
    }

    const categoryCheckboxChangeHandler = (isChecked, filter) => {
        if(isChecked) {
            ctx.setCategoriesFilter(prevState => {
                return [
                    ...prevState,
                    filter
                ]
            })

        } else {
            ctx.setCategoriesFilter(prevState => {
                let temp = [...prevState];
                let index = temp.indexOf(filter);
                if (index !== -1) {
                    temp.splice(index, 1);
                }
                ctx.setCategoriesFilter(temp);
            })
        }
    };

    const expandClickHandler = () => {
        setExpanded(() => {
            return !expanded;
        });
    }

    return (
        <div className={styles['filters-list']}>
            <div className={styles['filters-list__collapse']}>
                <button onClick={expandClickHandler}>{expanded ? '-' : '+'} Filters</button>
            </div>
            <div className={`${styles['filters-list__filters']} ${expanded ? styles['expanded'] : ''}`}>
                <ExpensesCheckboxFilter 
                    categories={props.categories}
                    onApplyFilter={filterCategoriesHandler}
                    isChecked={ctx.categories}
                    disabledList={props.disabledCategories}
                    onChange={categoryCheckboxChangeHandler}
                    type='category'
                    typePlural='Categories'
                />
                <ExpensesCheckboxFilter 
                    categories={props.subcategories.reduce((accumulator, current) => {
                        if(!accumulator.includes(current.subcategory)) {
                            return [...accumulator, current.subcategory];
                        }
                        return accumulator;
                    }, [])}
                    onApplyFilter={filterSubcategoriesHandler}
                    isChecked={ctx.subcategories}
                    disabledList={props.disabledSubcategories}
                    onChange={subcategoryCheckboxChangeHandler}
                    type='subcategory'
                    typePlural='Subcategories'
                />
                <div className={styles['filter-date']}>
                    <ExpensesSelectionFilter
                        selectedFilter={ctx.year}
                        onApplyFilter={filterYearHandler}
                        label='Filter by year'
                    >
                        <option value='All'>All Years</option>
                        <option value='2022'>2022</option>
                        <option value='2021'>2021</option>
                        <option value='2020'>2020</option>
                        <option value='2019'>2019</option>
                    </ExpensesSelectionFilter>
                    {
                        ctx.year !== 'All' ?
                            <ExpensesSelectionFilter
                                selectedFilter={ctx.month}
                                onApplyFilter={filterMonthHandler}
                                label='Filter by month'
                            >
                                <option value='All'>All Months</option>
                                <option value='0'>Jan</option>
                                <option value='1'>Feb</option>
                                <option value='2'>Mar</option>
                                <option value='3'>Apr</option>
                                <option value='4'>May</option>
                                <option value='5'>Jun</option>
                                <option value='6'>Jul</option>
                                <option value='7'>Aug</option>
                                <option value='8'>Sep</option>
                                <option value='9'>Oct</option>
                                <option value='10'>Nov</option>
                                <option value='11'>Dec</option>
                            </ExpensesSelectionFilter>
                        : ''
                    }
                </div>
                <div className={styles['sort-by']}>
                    <ExpensesSelectionFilter
                        selectedFilter={ctx.sortingOption}
                        onApplyFilter={sortingOptionHandler}
                        label='Sort by'
                    >
                        <option value='Date'>Date</option>
                        <option value="Amount">Amount</option>
                        <option value="Credit">Credit Card</option>
                    </ExpensesSelectionFilter>
                    <ExpensesSelectionFilter
                        selectedFilter={ctx.sortingOrder}
                        onApplyFilter={sortingOrderHandler}
                    >
                        <option value="Desc">Descending</option>
                        <option value='Asc'>Ascending</option>
                    </ExpensesSelectionFilter>
                </div>
            </div>
        </div>
    )
}

export default FiltersList
