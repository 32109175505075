import React, { useContext } from 'react';
import { PhotoGalleryLayoutContext } from '../../../store/PhotoGalleryLayoutContext';
import styles from './LayoutToggle.module.css';

const LayoutToggle = () => {
    const layoutContext = useContext(PhotoGalleryLayoutContext);

    const clickHandler = event => {
        if(layoutContext.layoutType === "Grid") {
            layoutContext.setLayoutType("List");
        } else layoutContext.setLayoutType("Grid");
        
    }

    return (
        <button className={`${styles['layout-toggle']} custom-btn__primary`} onClick={clickHandler}>
            Layout | {layoutContext.layoutType}
        </button>
    )
}

export default LayoutToggle
