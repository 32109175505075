import react, { useState } from 'react';
import DUMMY_EXPENSES from '../containers/ExpenseTracker/dummy-data';

const ExpenseFilterContext = react.createContext({
    year: 'All',
    month: 'All',
    categories: [],
    subcategories: [],
    sortOption: 'Date',
    sortingOrder: 'Desc',
});

export const ExpenseFilterContextProvider = (props) => {
    const categories = DUMMY_EXPENSES.reduce((accumulatedCategories, currentExpense) => {
        if(!accumulatedCategories.includes(currentExpense.category)) {
            return [...accumulatedCategories, currentExpense.category];
        }
        else
            return accumulatedCategories;
    }, []);

    const subcategories = DUMMY_EXPENSES.reduce((allSubcategories, currentExpense) => {
        if(!allSubcategories.includes(currentExpense.subcategory)) {
            return [...allSubcategories, currentExpense.subcategory]
        }
        else
            return allSubcategories;
    }, []);

    const [yearFilter, setYearFilter] = useState('All');
    const [monthFilter, setMonthFilter] = useState('All');
    const [categoriesFilter, setCategoriesFilter] = useState(categories);
    const [subcategoriesFilter, setSubcategoriesFilter] = useState(subcategories);
    const [sortingOption, setSortingOption] = useState('Date');
    const [sortingOrder, setSortingOrder] = useState('Desc')

    return (
        <ExpenseFilterContext.Provider 
            value={{
                year: yearFilter,
                month: monthFilter,
                categories: categoriesFilter,
                subcategories: subcategoriesFilter,
                sortingOption: sortingOption,
                sortingOrder: sortingOrder,
                setYearFilter: setYearFilter,
                setMonthFilter: setMonthFilter,
                setCategoriesFilter: setCategoriesFilter,
                setSubcategoriesFilter: setSubcategoriesFilter,
                setSortingOption: setSortingOption,
                setSortingOrder: setSortingOrder,
            }}
        >
            {props.children}
        </ExpenseFilterContext.Provider>
    );
}

export { ExpenseFilterContext };
