import React, { useState } from 'react';
import styles from './SearchBar.module.css';

const SearchBar = () => {
    const [searchType, setSearchType] = useState('Title');

    const typeChangeHandler = event => {
        setSearchType(event.target.value);
    }

    const searchHandler = event => {
        setTimeout(() => {
            event.target.blur();
        }, 500);
    }

    return (
        <div className={styles['search-wrapper']}>
            <div className={styles['search-inputs']}>
                <input type="text" placeholder="Search..." />
                <span> | </span>
                <select className={styles['search-type']} onChange={typeChangeHandler}>
                        <option value="Title">
                                Title
                        </option>
                        <option value="Description">
                                Description
                        </option>
                </select>
            </div>
            <div>
                <button onClick={searchHandler} className="custom-btn__primary">Go</button>
            </div>
        </div>
    )
}

export default SearchBar
