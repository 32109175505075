import React, { useContext } from 'react';
import styles from './Cell.module.css';
import { FreeCellContext } from '../../../store/FreeCellContext';
import PlayingCard from '../../../components/PlayingCard/PlayingCard';

const Cell = (props) => {
  const freeCellContext = useContext(FreeCellContext);
  let currentCell = freeCellContext.gameState[1][props.cellIndex];
  let topCardIndex = currentCell.length - 1;
  let cell = null;

  const cardClickHandler = () => {
    if(freeCellContext.cardSelection && (freeCellContext.cardSelection[2] !== 1 || props.cellIndex !== freeCellContext.cardSelection[0])) {
      // try to add card selection to cell
      if(currentCell.length === 0) {
        let src = freeCellContext.cardSelection;

        if(src[2] === 2) {
          let selectedCascade = freeCellContext.gameState[2][src[0]];
          src[1] = selectedCascade.length - 1;
        }

        let gameAction = {
          type: "moveToCell",
          source: src,
          destination: props.cellIndex
        }

        freeCellContext.dispatchGameState(gameAction);
      }
      freeCellContext.setCardSelection(null);
    } else if(freeCellContext.cardSelection && props.cellIndex === freeCellContext.cardSelection[0]) {
      freeCellContext.setCardSelection(null);
    } else if(freeCellContext.gameState[1][props.cellIndex].length > 0) {
      freeCellContext.setCardSelection([props.cellIndex, 0, 1]);
    }
  }

  if(freeCellContext.gameState[1][props.cellIndex].length  > 0) {
    let topCard = freeCellContext.gameState[1][props.cellIndex][0];
    cell = (
      <div className={styles.cardWrapper} >
          <PlayingCard 
              rank={topCard.rank}
              suit={topCard.suit}
              faceGraphic={topCard.faceGraphic}
              cardIndex={0}
              highlighted={freeCellContext.cardSelection && freeCellContext.cardSelection[2] === 1 && props.cellIndex === freeCellContext.cardSelection[0]}
              onClick={cardClickHandler}
          />
      </div>
    );
  }
  
  return (
    cell !== null ?
      <div className={styles.cell}>{cell}</div>
      :
      <div className={styles.cellEmpty} onClick={cardClickHandler}></div>
  )
}

export default Cell