import React from 'react';
import Cascade from '../Cascade/Cascade';
import styles from './CascadesWrapper.module.css';

const CascadesWrapper = (props) => {
    let cascades = [];

    for(let index = 0; index < 8; index++) {
        cascades.push(<Cascade cascadeIndex={index} key={`cascade-${index}`} />);
    };

    return (
        <div className={styles.cascadesWrapper}>
            {cascades}
        </div>
    )
}

export default CascadesWrapper