import React from 'react';
import styles from './Category.module.css';

let CATEGORY_COLORS = {
    'Nature': 'category__nature',
    'Car': 'category__car',
    'Music': 'category__music',
};

const Category = (props) => {
    const clickHandler = event => {
        if(props.clickable) {
            props.onClick();
        }
    }

    const categoryTagColor = CATEGORY_COLORS[props.categoryName];

    return (
        <div 
            className={`${styles['category-tag']} ${styles[categoryTagColor]}`} 
            onClick={clickHandler}
        >
            {props.categoryName}
            {
                props.clickable ?
                <span>+</span> : ''
            }
        </div>
    )
}

export default Category
