import React from "react";
import styles from "./PlayingCard.module.css";

const PlayingCard = (props) => {
    let rank = props.rank;
    switch(props.rank) {
        case 1:
            rank = "A";
            break;
        case 11:
            rank = "J";
            break;
        case 12:
            rank = "Q";
            break;
        case 13:
            rank = "K";
    }

    let suit;
    switch(props.suit) {
        case 0:
            suit = <i className={`${styles.redSuit} fas fa-heart`}></i>;
            break;
        case 1:
            suit = <i className={`${styles.blackSuit} fas fa-paw`}></i>;
            break;
        case 2:
            suit = <i className={`${styles.redSuit} fas fa-pepper-hot`}></i>
            break;
        case 3:
            suit = <i className={`${styles.blackSuit} fas fa-coffee`}></i>;
    }

    let faceGraphic;
    if(props.faceGraphic) {
        faceGraphic = props.faceGraphic;
    } else if(props.rank > 1 && props.rank < 11){
        faceGraphic = [];
        for(let i = 0; i < props.rank; i++) {
            faceGraphic.push(suit);
        }
    }

    let highlightedStyle = props.highlighted ? styles.highlighted : '';

    return (
        <div className={`${styles.card} ${highlightedStyle}`} onClick={() => props.onClick(props.cardIndex)}>
            <div className={styles.cardInfo}>
                <span className={styles.rank}>{rank}</span>
                <span className={styles.suit}>{suit}</span>
            </div>
            <div className={styles.cardFace}>
                {
                    props.faceGraphic ?
                    <img className={styles.faceGraphic} src={faceGraphic} />
                    :
                    <span className={styles.faceGraphic} >{faceGraphic}</span>
                }
            </div>
        </div>
    );
};

export default PlayingCard;
