import styles from './ExpensesCheckboxFilter.module.css';
import React from 'react';
import Checkbox from '../../../../components/UI/Checkbox/Checkbox';

const ExpensesCheckboxFilter = (props) => {
    const checkboxChangeHandler = (isChecked, filter) => {
        props.onChange(isChecked, filter);
    };

    return (
        <div className={styles['expenses-filter']}>
            <div className={styles['expenses-filter__heading']}>
                <span>Filter by {props.type}</span>
            </div>
            <ul className={styles['expenses-filter__control']}>
                {
                    props.categories.map(category => {
                        return (
                            <li className={styles['expenses-filter__checkbox']} key={category}>
                                <Checkbox 
                                    label={category}
                                    isChecked={props.isChecked.includes(category)}
                                    onChange={checkboxChangeHandler}
                                    isDisabled={props.disabledList.includes(category)}
                                />
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    )
}

export default ExpensesCheckboxFilter
