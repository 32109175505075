import React, { useState, useReducer, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import styles from "./Oware.module.css";
import LoadingSpinner from "../../components/UI/LoadingSpinner/LoadingSpinner";
import { getSum } from "../../utilities/general";
import { axiosPost } from "../../utilities/apiUtility";

const initialGameState = {
  gamePhase: "pregame",
  playerMove: null,
  compMove: null,
  compCups: [3, 3, 3, 3, 3, 3, 0],
  humanCups: [3, 3, 3, 3, 3, 3, 0],
  errorMessage: null,
};

const executeMove = (humanCups, compCups, cupIndex, isComputerMove) => {
  let currentHumanCups = [...humanCups];
  let currentCompCups = [...compCups];
  let stones;

  if (isComputerMove) {
    stones = currentCompCups[cupIndex];
    currentCompCups[cupIndex] = 0;
  } else {
    stones = currentHumanCups[cupIndex];
    currentHumanCups[cupIndex] = 0;
  }
  let currentIndex = cupIndex + 1;
  let isComputersCups = isComputerMove;

  while (stones > 0) {
    if (currentIndex > 5) {
      currentIndex = 0;
      if (isComputerMove && isComputersCups) {
        // computer scores a goal
        currentCompCups[6]++;
        stones--;
      } else if (!isComputerMove && !isComputersCups) {
        // player scores a goal
        currentHumanCups[6]++;
        stones--;
      }
      isComputersCups = !isComputersCups;
    } else if (isComputersCups) {
      currentCompCups[currentIndex]++;
      stones--;
      if (
        stones === 0 &&
        currentCompCups[currentIndex] === 1 &&
        isComputerMove
      ) {
        // capture
        let capturedStones = currentHumanCups[5 - currentIndex];
        currentCompCups[6] += capturedStones;
        currentHumanCups[5 - currentIndex] = 0;
      }
      currentIndex++;
    } else {
      currentHumanCups[currentIndex]++;
      stones--;
      if (
        stones === 0 &&
        currentHumanCups[currentIndex] === 1 &&
        !isComputerMove
      ) {
        // capture
        let capturedStones = currentCompCups[5 - currentIndex];
        currentHumanCups[6] += capturedStones;
        currentCompCups[5 - currentIndex] = 0;
      }
      currentIndex++;
    }
  }

  // Check for Win
  let compStoneCount = getSum(currentCompCups.slice(0, 6));
  let humanStoneCount = getSum(currentHumanCups.slice(0, 6));
  let updatedPhase = isComputerMove ? "playerMove" : "compMove";
  if (compStoneCount === 0) {
    currentHumanCups[6] += humanStoneCount;
    for (let i = 0; i < 6; i++) {
      currentHumanCups[i] = 0;
    }
  } else if (humanStoneCount === 0) {
    currentCompCups[6] += compStoneCount;
    for (let i = 0; i < 6; i++) {
      currentCompCups[i] = 0;
    }
  }
  if (currentCompCups[6] >= 19) {
    updatedPhase = "lostGame";
  } else if (currentHumanCups[6] >= 19) {
    updatedPhase = "wonGame";
  }

  return {
    nextGamePhase: updatedPhase,
    humanCups: currentHumanCups,
    compCups: currentCompCups,
  };
};

const gameStateReducer = (state, action) => {
  switch (action.type) {
    case "newGame":
      return { ...initialGameState };
    case "changePhase":
      return { ...state, gamePhase: action.payload.gamePhase };
    case "playerMove":
      let playerStonesSelected = state.humanCups[action.payload.cupIndex];
      if (playerStonesSelected === 0) {
        return {
          ...state,
          errorMessage: "This cup is empty, please choose another.",
        };
      } else {
        let submittedPlayerMove = executeMove(
          state.humanCups,
          state.compCups,
          action.payload.cupIndex,
          false
        );

        return {
          ...state,
          playerMove: [action.payload.cupIndex + 1, playerStonesSelected],
          humanCups: submittedPlayerMove.humanCups,
          compCups: submittedPlayerMove.compCups,
          gamePhase: submittedPlayerMove.nextGamePhase,
          errorMessage: null,
        };
      }
    case "compMove":
      let compStonesSelected = state.compCups[action.payload.cupIndex];
      let move = executeMove(
        state.humanCups,
        state.compCups,
        action.payload.cupIndex,
        true
      );
      return {
        ...state,
        compMove: [action.payload.cupIndex + 1, compStonesSelected],
        humanCups: move.humanCups,
        compCups: move.compCups,
        gamePhase: move.nextGamePhase,
      };
    default:
      return state;
  }
};

const Oware = () => {
  const [gameboardState, dispatchGameboardState] = useReducer(
    gameStateReducer,
    initialGameState
  );
  const [showInstruction, setShowInstructions] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const getComputerMove = async () => {
    let payload = {
      gameState: [[...gameboardState.compCups], [...gameboardState.humanCups]],
    };

    // call api to get computer move
    // return value is zero indexed, i.e. 0-5
    let res = await axiosPost(
        'https://api.nnmcdonald-portfolio.com/oware', 
        payload
    );

    if(res.error) {
        return "to pass";
    }

    return res.data.compMove;
  };

  useEffect(() => {
    if (gameboardState.gamePhase === "compMove") {
      async function fetchData() {
        setLoadingSpinner(true);
        const nextCompMove = await getComputerMove();
        let action = {
          type: "compMove",
          payload: {
            cupIndex: nextCompMove,
          },
        };
        dispatchGameboardState(action);
        setLoadingSpinner(false);
      }
      fetchData();
    }
  }, [gameboardState.gamePhase]);

  const instructionHandler = () => {
    setShowInstructions((prevState) => {
      return !prevState;
    });
  };

  const initalizeGame = (playerChoice) => {
    if (playerChoice === "secondPlayer") {
      let action = {
        type: "compMove",
        payload: {
          cupIndex: 4,
        },
      };
      dispatchGameboardState(action);
    } else {
      let action = {
        type: "changePhase",
        payload: {
          gamePhase: "playerMove",
        },
      };
      dispatchGameboardState(action);
    }
  };

  const newGameHandler = () => {
    let action = {
      type: "newGame",
    };
    dispatchGameboardState(action);
  };

  const cupClickHandler = async (cupIndex) => {
    if (!loadingSpinner) {
      let action = {
        type: "playerMove",
        payload: {
          cupIndex: cupIndex,
        },
      };
      dispatchGameboardState(action);
    }
  };

  let winAnouncement = "";
  if (gameboardState.gamePhase === "wonGame") {
    winAnouncement = <p className={styles.winningMsg}>You Win!</p>;
  } else if (gameboardState.gamePhase === "lostGame") {
    winAnouncement = <p className={styles.losingMsg}>The Computer Won!</p>;
  }

  let gameArea =
    gameboardState.gamePhase !== "pregame" ? (
      <div>
        <div>
          <div className={styles.gameData}>
            {winAnouncement}
            {gameboardState.playerMove ? (
              <p id="HumanMove">{`You chose cup: ${gameboardState.playerMove[0]}, ${gameboardState.playerMove[1]} stone(s) dispersed`}</p>
            ) : (
              ""
            )}
            {gameboardState.compMove ? (
              <p id="ComputerMove">{`The computer chose cup: ${gameboardState.compMove[0]}, ${gameboardState.compMove[1]} stone(s) dispersed`}</p>
            ) : (
              ""
            )}
            {gameboardState.errorMessage ? (
              <p className={styles.errorMessage}>
                {gameboardState.errorMessage}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={styles.gameArea}>
          <button className={`${styles.compGoal} btn btn-lg btn-warning`}>
            {gameboardState.compCups[6]}
          </button>
          <div className={styles.cupsWrapper}>
            {gameboardState.compCups
              .slice(0, 6)
              .reverse()
              .map((stones, index) => (
                <button
                  key={`compCup${index}`}
                  className={`btn btn-lg btn-success ${styles.computerCup}`}
                >
                  {stones}
                </button>
              ))}
            {gameboardState.humanCups.slice(0, 6).map((stones, index) => (
              <button
                key={`humanCup${index}`}
                className="btn btn-lg btn-success"
                onClick={() => cupClickHandler(index)}
              >
                {stones}
              </button>
            ))}
          </div>
          <button className={`${styles.humanGoal} btn btn-lg`}>
            {gameboardState.humanCups[6]}
          </button>
        </div>
      </div>
    ) : (
      ""
    );

  let playerChoice =
    gameboardState.gamePhase === "pregame" ? (
      <div id="PlayerChoice">
        <PrimaryButton
          label="First Player"
          onClick={() => initalizeGame("firstPlayer")}
        />
        <PrimaryButton
          label="Second Player"
          onClick={() => initalizeGame("secondPlayer")}
        />
      </div>
    ) : (
      ""
    );

  let newGameButton =
    gameboardState.gamePhase !== "pregame" ? (
      <PrimaryButton label="New Game" onClick={newGameHandler} />
    ) : (
      ""
    );

  let gameInstructions = showInstruction ? (
    <div>
      <p>
        This is a version of mancala, the interface presents two rows of buttons
        representing cups with stones in them. The number on each button
        indicates the number of stones in the cup. The leftmost cup is the
        computer goal and the rightmost cup is the human player's goal. The top
        row is the computer's cups and the second row is the player's.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;On each turn a cup is chosen and the stones are
        dispersed one at a time counterclockwise. If the opponent's goal cup is
        reached it is skipped. If the last stone placed lands in an empty cup
        belonging to the current player, then the stones in the cup directly
        across are "captured" and placed in the current player's goal cup (for
        example: last stone lands in cup 2, captures enemy cup 5).
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;Play proceeds until one side has acquired the
        majority of the stones in their goal cup (19), or until one side runs
        out of stones. If one player runs out of stones then the opponent
        "captures" all remaining stones.
      </p>
    </div>
  ) : (
    ""
  );

  return (
    <Container id="owarePageWrap">
      {loadingSpinner ? <LoadingSpinner message="Calculating" /> : ""}
      <Row>
        <Col>
          <h1>Oware</h1>

          <div id="ProjectDescription">
            <p>
              The purpose of this project was to develop an A.I. program to play
              the game Oware (Please click "Instructions" to read more about the
              game rules). As part of the project this program won a class
              competition to determine the strongest program in a round robin
              style tournament. The original program was written in Java and can
              be viewed on my{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.github.com/nnmcdonald"
              >
                github
              </a>
              , however the version presented here uses Python to determine the
              computer move and Javascript (React) to run the game.
            </p>
          </div>
          {gameArea}
          <div className={styles.buttonsWrapper}>
            {playerChoice}
            <div id="OtherOptions">
              {newGameButton}
              <PrimaryButton
                label={
                  showInstruction ? "Hide Instructions" : "Show Instructions"
                }
                onClick={instructionHandler}
              />
            </div>
          </div>

          {gameInstructions}
        </Col>
      </Row>
    </Container>
  );
};

export default Oware;
