import React from "react";
import styles from "./Gameboard.module.css";
import boomImg from '../../../static/images/boom.png';

const Gameboard = (props) => {
  let board = [];
  let boardDims = [Object.keys(props.gameState).length, props.gameState[0].length];

  const cellClickHandler = (x, y) => {
    props.onRevealClick(x, y);
  }

  for (let i = 0; i < boardDims[0]; i++) {
    let currentRow = [];
    for (let j = 0; j < boardDims[1]; j++) {
      let cellWidth = (1 / 18) * 100 + "%";
      if (boardDims[1] === 16) {
        cellWidth = (1 / 16) * 100 + "%";
      } else if (boardDims[1] === 30) {
        cellWidth = (1 / 30) * 100 + "%";
      }

      let cellValue = '';
      if(props.gameState[i][j] === -1 && props.activeGameCells[i][j] === 1) {
        cellValue = <img className={styles.bomb} src={boomImg} alt="Mine Activated" />;
      } else if (props.activeGameCells[i][j] === 1) {
        cellValue = <span>{props.gameState[i][j]}</span>;
      } else if (props.activeGameCells[i][j] === 2) {
        cellValue = <span><i className="fas fa-flag"></i></span>;
      }

      let cellStateCssClasses = '';
      if(props.activeGameCells[i][j] === 1) {
        cellStateCssClasses = styles.activeCell;
      } else if (props.activeGameCells[i][j] === 2) {
        cellStateCssClasses = styles.flagged;
      }

      currentRow.push(
        <div
          key={"gameCell_" + i + "_" + j}
          className={`${styles.gameCell} ${styles["cellVal" + props.gameState[i][j]]} ${cellStateCssClasses}`}
          style={{ height: "20px", width: cellWidth, maxWidth: "20px" }}
          onClick={() => cellClickHandler(i, j)}
        >
            {cellValue}
        </div>
      );
    }
    board.push(
      <div key={"gameRow_" + i} className={styles.gameRow}>
        {currentRow}
      </div>
    );
  }

  return <div className={styles.gameboard}>{board}</div>;
};

export default Gameboard;
