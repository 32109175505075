import React, { useEffect, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "../../static/css/bootstrap.css";
import "./App.css";
import Resume from "../Resume/Resume";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { AppContext } from "../../store/AppContext";
import { ExpenseFilterContextProvider } from '../../store/ExpenseFilterContext';
import PhotoGallery from "../PhotoGallery/PhotoGallery";
import ExpenseTracker from "../ExpenseTracker/ExpenseTracker";
import PokeTypeCalc from "../PokeTypeCalc/PokeTypeCalc";
import PageNotFound from "../PageNotFound/PageNotFound"
import PidgeyCalc from "../PidgeyCalc/PidgeyCalc";
import SnakePhoneVariation from "../SnakePhoneVariation/SnakePhoneVariation";
import Minesweeper from "../Minesweeper/Minesweeper";
import Oware from "../Oware/Oware";
import AboutAWSArchitecture from "../AboutAWSArchitecture/AboutAWSArchitecture";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import FamilyChristmasCard from "../ChristmasCard/FamilyChristmasCard/FamilyChristmasCard";
import DemoChristmasCard from "../ChristmasCard/DemoChristmasCard/DemoChristmasCard";
import DigiDragonChristmasCard from "../ChristmasCard/DigiDragonChristmasCard/DigiDragonChristmasCard";
import MothersDayFreeCell from "../MothersDayFreeCell/MothersDayFreeCell";
import { FreeCellContextProvider } from "../../store/FreeCellContext";
import FathersDayCard2022 from "../FathersDayCard2022/FathersDayCard2022";

function App() {
  const appCtx = useContext(AppContext);
  
  useEffect(() => {
    const headingBannerElement = document.getElementById("heading-banner-root");
    if (appCtx.headingBanner === "") {
      headingBannerElement.style.height = "0px";
    } else {
      headingBannerElement.style.height = "6rem";
    }
    window.addEventListener("scroll", () => {
      if (window.scrollY < 180 && appCtx.headingBanner !== "") {
        headingBannerElement.style.height = "6rem";
      } else if (appCtx.headingBanner === "") {
        headingBannerElement.style.height = "0px";
      }
    });
  }, [appCtx.headingBanner, appCtx.darkMode]);

  let darkModeEnabled = appCtx.darkMode ? 'dark-mode' : '';
  
  return (
    <Router>
      <div className={`${darkModeEnabled} body-wrapper`}>
        <Header />
        <div id="pageWrap">
          <div id="bodyContent">
            <Routes>
              <Route path="/about/aws-architecture" element={<AboutAWSArchitecture />} />
              <Route path="/games/minesweeper" element={<Minesweeper />} />
              <Route path="/games/oware" element={<Oware />} />
              <Route path="/pidgeyCalc" element={<PidgeyCalc />} />
              <Route path="/pokeTypes" element={<PokeTypeCalc />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/games/snake-variation" element={<SnakePhoneVariation />} />
              <Route path="/react/expense-tracker" element={<ExpenseFilterContextProvider ><ExpenseTracker /></ExpenseFilterContextProvider>} />
              <Route path="/react/photo-gallery" element={<PhotoGallery />} />
              <Route path="/christmasCard" element={<DemoChristmasCard />} />
              <Route path="/familyChristmasCard" element={<FamilyChristmasCard />} />
              <Route path="/familyChristmasCard/:viewerId" element={<FamilyChristmasCard />} />
              <Route path="DigiDragonChristmasCard" element={<DigiDragonChristmasCard />} />
              <Route path="/mothersDay/freeCell" element={<FreeCellContextProvider ><MothersDayFreeCell /></FreeCellContextProvider>} />
              <Route path="/fathersDay/2022" element={<FathersDayCard2022 />} />
              <Route path="/" element={<Resume />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
