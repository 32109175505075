const dragonImgs = {
    oneA: require("../../static/images/dragon-tree1a.svg").default,
    oneB: require("../../static/images/dragon-tree1b.svg").default,
    twoA: require("../../static/images/dragon-tree2a.svg").default,
    twoB: require("../../static/images/dragon-tree2b.svg").default,
    threeA: require("../../static/images/dragon-tree3a.svg").default,
    threeB: require("../../static/images/dragon-tree3b.svg").default,
    fourA: require("../../static/images/dragon-tree4a.svg").default,
    fourB: require("../../static/images/dragon-tree4b.svg").default,
    fiveA: require("../../static/images/dragon-tree5a.svg").default,
    fiveB: require("../../static/images/dragon-tree5b.svg").default,
    sixA: require("../../static/images/dragon-tree6a.svg").default,
    sixB: require("../../static/images/dragon-tree6b.svg").default,
    sevenA: require("../../static/images/dragon-tree7a.svg").default,
    sevenB: require("../../static/images/dragon-tree7b.svg").default,
    eightA: require("../../static/images/dragon-tree8a.svg").default,
    eightB: require("../../static/images/dragon-tree8b.svg").default,
    nineA: require("../../static/images/dragon-tree9a.svg").default,
    nineB: require("../../static/images/dragon-tree9b.svg").default,
    tenA: require("../../static/images/dragon-tree10a.svg").default,
    tenB: require("../../static/images/dragon-tree10b.svg").default,
    elevenA: require("../../static/images/dragon-tree11a.svg").default,
    elevenB: require("../../static/images/dragon-tree11b.svg").default,
}

export default dragonImgs;