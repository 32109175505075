import React from "react";
import styles from "./TypeWeaknessTable.module.css";

const TypeWeaknessTable = (props) => {
  const typeWeaknessIndexes = [
    [1, 2, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    [1, 1, 2, 1, 1, 0.5, 0.5, 1, 1, 1, 1, 1, 1, 2, 1, 1, 0.5, 2],
    [1, 0.5, 1, 1, 0, 2, 0.5, 1, 1, 1, 1, 0.5, 2, 1, 2, 1, 1, 1],
    [1, 0.5, 1, 0.5, 2, 1, 0.5, 1, 1, 1, 1, 0.5, 1, 2, 1, 1, 1, 0.5],
    [1, 1, 1, 0.5, 1, 0.5, 1, 1, 1, 1, 2, 2, 0, 1, 2, 1, 1, 1],
    [0.5, 2, 0.5, 0.5, 2, 1, 1, 1, 2, 0.5, 2, 2, 1, 1, 1, 1, 1, 1],
    [1, 0.5, 2, 1, 0.5, 2, 1, 1, 1, 2, 1, 0.5, 1, 1, 1, 1, 1, 1],
    [0, 0, 1, 0.5, 1, 1, 0.5, 2, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1],
    [0.5, 2, 0.5, 0, 2, 0.5, 0.5, 1, 0.5, 2, 1, 0.5, 1, 0.5, 0.5, 0.5, 1, 0.5],
    [1, 1, 1, 1, 2, 2, 0.5, 1, 0.5, 0.5, 2, 0.5, 1, 1, 0.5, 1, 1, 0.5],
    [1, 1, 1, 1, 1, 1, 1, 1, 0.5, 0.5, 0.5, 2, 2, 1, 0.5, 1, 1, 1],
    [1, 1, 2, 2, 0.5, 1, 2, 1, 1, 2, 0.5, 0.5, 0.5, 1, 2, 1, 1, 1],
    [1, 1, 0.5, 1, 2, 1, 1, 1, 0.5, 1, 1, 1, 0.5, 1, 1, 1, 1, 1],
    [1, 0.5, 1, 1, 1, 1, 2, 2, 1, 1, 1, 1, 1, 0.5, 1, 1, 2, 1],
    [1, 2, 1, 1, 1, 2, 1, 1, 2, 2, 1, 1, 1, 1, 0.5, 1, 1, 1],
    [1, 1, 1, 1, 1, 1, 1, 1, 1, 0.5, 0.5, 0.5, 0.5, 1, 2, 2, 1, 2],
    [1, 2, 1, 1, 1, 1, 2, 0.5, 1, 1, 1, 1, 1, 0, 1, 1, 0.5, 2],
    [1, 0.5, 1, 2, 1, 1, 0.5, 1, 2, 1, 1, 1, 1, 1, 1, 0, 0.5, 1],
  ];

  const getWeaknesses = () => {
    let weaknesses = [];
    for (let i = 0; i < typeWeaknessIndexes.length; i++) {
      weaknesses[i] = 1;
    }
    if (props.first > -1) {
      for (let j = 0; j < typeWeaknessIndexes.length; j++) {
        weaknesses[j] *= typeWeaknessIndexes[props.first][j];
      }
    }
    if (props.second > -1) {
      for (let j = 0; j < typeWeaknessIndexes.length; j++) {
        weaknesses[j] *= typeWeaknessIndexes[props.second][j];
      }
    }
    return weaknesses;
  };

  const weaknessTable = () => {
      let tableElement = [];
      let weaknesses = getWeaknesses();
      
      for(let i=0; i < props.pokemonTypes.length; i++) {
        let typeData;
        let typeHeader = <div className={styles.typeLabel}>{props.pokemonTypes[i]}</div>;
        switch(weaknesses[i]) {
          case 0:
            typeData = <div className={`${styles.damageMultiplier} ${styles.zero}`}>{weaknesses[i]}x</div>;
            break;
          case 0.25:
            typeData = <div className={`${styles.damageMultiplier} ${styles.quarter}`}>{weaknesses[i]}x</div>;
          break;
          case 0.5:
            typeData = <div className={`${styles.damageMultiplier} ${styles.half}`}>{weaknesses[i]}x</div>;
            break;
          case 2:
            typeData = <div className={`${styles.damageMultiplier} ${styles.double}`}>{weaknesses[i]}x</div>;
            break;
          case 4:
            typeData = <div className={`${styles.damageMultiplier} ${styles.quadruple}`}>{weaknesses[i]}x</div>;
            break;
          default:
            typeData = <div className={`${styles.damageMultiplier} ${styles.default}`}>{weaknesses[i]}x</div>;
        };
        tableElement.push(<div key={props.pokemonTypes[i]} className={styles.typeWeaknessData}>{typeHeader}{typeData}</div>)
      };

      return <div className={styles.weaknessTable}>{tableElement}</div>;
  }

  return weaknessTable();
};

export default TypeWeaknessTable;
