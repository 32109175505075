import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Privacy Policy</h1>
          <p>
            I do not collect any personal information. If you have
            visited this site before you may have seen that I was previously utilizing
            Google Analytics, but I have recently redesigned the site and
            therefore am no longer tracking any info at this time.
            <br/> 
            If this ever changes this policy will be updated accordingly, and
            a prominent notice will be posted accross the site to allow for 
            opting out of any information collection.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
