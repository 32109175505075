const ExperienceInfo = () => {
      return(
        <div id="experience-wrapper">
          <div className="experience-info" style={{position:"relative"}}>
            <div id="experience-anchor" className="anchor"></div>
            <h1>Work Experience</h1><br/>

            <p><b>LeadVenture</b><br/>
                  Wilsonville, OR<br/>
                  Marketing Software Engineer
            </p>
            <p className="float-right">June 2021 - Current</p><br/>
            <ul className="marker">
              <li>Work with data lake / analysis / data science teams to improve the quality of our data capture and downstream learning.</li>
              <li>Work as part of an agile team (Scrum) against a prioritized backlog.</li>
              <li>Develop marketing tools for internal teams with React, Redux, Node, CSS.</li>
              <li>Develop apps/software with AWS (S3, Lambda, Glue, Athena)</li>
            </ul>
            
            <p><b>Dealer Spike</b><br/>
                  Wilsonville, OR<br/>
                  Technical Support/Junior Developer
            </p>
            <p className="float-right">January 2020 - June 2021</p><br/>
            <ul className="marker">
              <li>Take in client requests via email and phone</li>
              <li>Maintain, update, and implement changes to clients' websites using HTML, CSS, and JavaScript</li>
              <li>Troubleshoot / Debug platform or website issues</li>
              <li>Act as a technical liaison to clients, leading technical discussions during implementations</li>
              <li>Identify any client issues and work with internal teams to create a strategy to troubleshoot and resolve problem</li>
              <li>Adhere to a 24 hour turn-around standard to ensure top level support for clients</li>
              <li>Work independently and be a part of a group setting</li>
            </ul>
            
            <p><b>Alaska Resources Library & Information Services (ARLIS)</b><br/>
                Anchorage, Ak<br/>
                Programmer/Analyst Consultant
            </p>
            <p className="float-right">September 2018 – May 2019</p><br/>
            <ul className="marker">
              <li>Developed a new website in Django (at www.arlis.org) to replace ARLIS’ existing site</li>
              <li>Established clear and thorough site requirements and specifications in conjunction with the management team</li>
              <li>Maintained open and succinct communication with the management team throughout the development process</li>
              <li>Streamlined site functionality, eliminated numerous redundancies and inaccuracies</li>
              <li>Designed content management tools to help the ARLIS management team maintain the site</li>
              <li>Created custom HTML templates</li>
              <li>Performed testing with Python’s unittest framework</li>
              <li>Deployed site to Ubuntu Linux server with Apache</li>
            </ul>
          </div>
        </div>
      );
  };
  
  export default ExperienceInfo;