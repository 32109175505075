import styles from './Checkbox.module.css';

import React from 'react'

const Checkbox = (props) => {
    const changeHandler = (event) => {
        props.onChange(event.target.checked, event.target.value)
    }

    return (
        <div className={styles['checkbox']}>
            <input 
                type="checkbox" 
                defaultChecked={props.isChecked}
                onChange={changeHandler}
                value={props.label}
                disabled={props.isDisabled}
            />
            <label>{props.label}</label>
        </div>
    )
}

export default Checkbox
