import React, { useState } from 'react';
import CascadesWrapper from '../FreeCell/CascadesWrapper/CascadesWrapper';
import styles from './MothersDayFreeCell.module.css';
import FoundationsWrapper from '../FreeCell/FoundationsWrapper/FoundationsWrapper';
import CellsWrapper from '../FreeCell/CellsWrapper/CellsWrapper';
import { Container } from 'react-bootstrap';
import PlayingCard from '../../components/PlayingCard/PlayingCard';
import Modal from '../../components/Modal/Modal';
import ModalsWrapper from '../../components/Modal/ModalsWrapper';
import aceHeartsImage from '../../static/images/mothersDayFreeCell/IMG-2620.JPG';
import jackHeartsImage from '../../static/images/mothersDayFreeCell/IMG-2652.JPG';
import queenHeartsImage from '../../static/images/mothersDayFreeCell/IMG-2776.JPG';
import kingHeartsImage from '../../static/images/mothersDayFreeCell/FB_IMG_1651981935015.jpg';
import aceCoffeeImage from '../../static/images/mothersDayFreeCell/IMG_9011.JPG';
import jackCoffeeImage from '../../static/images/mothersDayFreeCell/IMG_9012.JPG';
import queenCoffeeImage from '../../static/images/mothersDayFreeCell/IMG_9027.JPG';
import kingCoffeeImage from '../../static/images/mothersDayFreeCell/IMG_9035.JPG';
import pawAceImage from '../../static/images/cassie-you-gonna-eat-that.jpeg';
import pawJackImage from '../../static/images/mothersDayFreeCell/IMG-9542.JPG';
import pawQueenImage from '../../static/images/mothersDayFreeCell/IMG-9540.JPG';
import pawKingImage from '../../static/images/loki-sleeping.jpg';
import pepperAceImage from '../../static/images/mothersDayFreeCell/indoor-gardening.png';
import pepperJackImage from '../../static/images/mothersDayFreeCell/IMG_1341.jpeg';
import pepperQueenImage from '../../static/images/mothersDayFreeCell/julie-mom-garden.png';
import pepperKingImage from '../../static/images/mothersDayFreeCell/IMG_1340.jpeg';

const cardImages = [
    aceHeartsImage,
    jackHeartsImage,
    queenHeartsImage,
    kingHeartsImage,
    pawAceImage,
    pawJackImage,
    pawQueenImage,
    pawKingImage,
    pepperAceImage,
    pepperJackImage,
    pepperQueenImage,
    pepperKingImage,
    aceCoffeeImage,
    jackCoffeeImage,
    queenCoffeeImage,
    kingCoffeeImage,
]

const MothersDayFreeCell = () => {
    const [showIntro, setShowIntro] = useState(true);

    const hideIntro = () => {
        setShowIntro(false);
    }

    let faceCards = [];

    let ranks = [1,11,12,13];
    let imageIndex = 0;
    for(let i = 0; i < 4; i++) {
        for(let j = 0; j < 4; j++) {
            let card = { rank: ranks[j], suit: i };
            card.faceGraphic = cardImages[imageIndex];
            imageIndex++;
            faceCards.push(card);
        }
    }

    let clickHandler = (index) => {

    }

    let cardPreview = [];
    faceCards.forEach((card, index) => {
        cardPreview.push(
            <div className={styles.cardWrapper} key={`card-${index}`} >
                <PlayingCard 
                    rank={card.rank}
                    suit={card.suit}
                    faceGraphic={card.faceGraphic}
                    cardIndex={0}
                    highlighted={false}
                    onClick={clickHandler}
                />
            </div>
        )
    });

    return (
        <Container>
            <ModalsWrapper>
                { 
                showIntro ? 
                <Modal onHideModal={hideIntro} darkMode={true}>
                    <div className="intro-content">
                    <h1>Happy Mother's Day!</h1>
                    <p>
                        I've created this FreeCell game for you with personal photos on the face cards.<br/>
                        I couldn't find icons for all of the standard suits so I have used: <br/>
                        <i className={`${styles.redSuit} fas fa-heart`}></i> Hearts, <i className={`${styles.redSuit} fas fa-pepper-hot`}></i> Peppers, <i className={`${styles.blackSuit} fas fa-paw`}></i> Paws, and <i className={`${styles.blackSuit} fas fa-coffee`}></i> Coffee <br/>
                        To play, just click a card and then click where you would like to move it. <br/>
                        Note: it does not currently check for a win automatically.
                    </p>
                    </div>
                    <button onClick={hideIntro} className="custom-btn__primary">Play</button>
                    <div className={styles.cardsPreview}>
                        {cardPreview}
                    </div>
                </Modal> : '' 
                }
            </ModalsWrapper>
            MothersDayFreeCell
            <div className={styles.gameWrapper}>
                <div className={styles.topRow}>
                    <FoundationsWrapper count={4} />
                    <CellsWrapper count={4} />
                </div>
                
                <CascadesWrapper />
            </div>
        </Container>
    )
}

export default MothersDayFreeCell