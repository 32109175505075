import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./PidgeyCalc.module.css";

const PidgeyCalc = () => {
  const [inputError, setInputError] = useState(false);
  const [pidgeyCount, setPidgeyCount] = useState("0");
  const [pidgeottoCount, setPidgeottoCount] = useState("0");
  const [candyCount, setCandyCount] = useState("0");
  const [calcResults, setCalcResults] = useState([]);

  let inputErrorMessage = inputError ? (
    <div className={styles.inputError}>
      <p>Please only enter numeric values.</p>
    </div>
  ) : (
    ""
  );

  const inputChangeHandler = (event, setStateCallback) => {
    let input = event.target.value;
    if (!isNaN(input)) {
      setStateCallback(input);
      if (inputError) {
        setInputError(false);
      }
    } else setInputError(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!inputError) {
      let xp = 0;
      let results = [];

      let pidgey = parseInt(pidgeyCount);
      let candy = parseInt(candyCount);
      let pidgeotto = parseInt(pidgeottoCount);
      let tPidgey = 0;
      let tPidgeotto = 0;

      let x = candy + pidgey + pidgeotto;
      let rowKey = 0;

      //checks to see if there are enough pidgey and candy to evolve any pidgey
      while (x > 12 && pidgey > 0) {
        let startPidgey = pidgey;
        let startPidgeotto = pidgeotto;
        let tempCandy = 0;

        //determines how many pidgeotto and pidgey to transfer if the player has any pidgeotto

        //if the player has 12 or more candy then no pidgeotto or pidgey are transferred
        if (candy >= 12) {
          tempCandy = candy % 12;
          candy = candy - (candy % 12);
        } else if (x >= 12 && pidgeotto > 0) {
          //if there isn't enough pidgeotto and candy to evolve a pidgey then this if block determines
          //how many pidgey to transfer as well
          if (pidgeotto + candy < 12) {
            tPidgey = 12 - ((x - pidgey) % 12);
            tPidgeotto = pidgeotto % 12;
            pidgey = pidgey - tPidgey;
            pidgeotto = pidgeotto - tPidgeotto;
            candy = x - pidgey;
          } else {
            tPidgeotto = 12 - candy;
            pidgeotto = pidgeotto - tPidgeotto;
            candy = x - pidgey - pidgeotto;
          }
        }
        //if the player doesn't have any pidgeotto then this determines how many pidgey to transfer.
        else {
          tPidgey = (x - pidgeotto) % 12;
          pidgey = tPidgey;
          candy = x - tPidgey - tPidgeotto;
        }
        //checks if the amount of evolutions exceeds the total pidgey
        while (candy / 12 > pidgey) {
          //adds 12 pidgey back and takes away 12 candies unless doing so exceeds the original
          //pidgey count.
          if (pidgey + 12 <= startPidgey) {
            pidgey = pidgey + 12;
            candy = candy - 12;
          } else {
            tempCandy = (x - startPidgey) % 12;
            candy = x - startPidgey - tempCandy;
            pidgey = startPidgey;
          }
        }
        results.push(
            <Row key={rowKey}>
              <Col>
                <p>
                  Transfer: {startPidgey - pidgey} pidgey, and{" "}
                  {startPidgeotto - pidgeotto} Pidgeotto.
                  <br /> Resulting in: {candy + tempCandy} candies, {pidgey}{" "}
                  pidgey, and {pidgeotto} pidgeotto.
                </p>
              </Col>
            </Row>
          );

        rowKey++;
        let evolves = Math.floor(candy / 12);
        pidgeotto = pidgeotto + evolves;

        //calculates candy and pidgey after evolutions
        candy = candy - 12 * evolves + tempCandy;
        pidgey = pidgey - evolves;
        xp += evolves * 500;
        tPidgey = 0;
        tPidgeotto = 0;
        x = candy + pidgey + pidgeotto;

        results.push(
            <Row key={rowKey}>
              <Col>
                <p>
                  Evolve {evolves} pidgey.
                  <br />
                  Resulting in: {candy} candies, {pidgey} pidgey, and {pidgeotto}{" "}
                  pidgeotto.
                  <br />
                  Total xp earned so far: {xp}
                </p>
              </Col>
            </Row>
          );
          
        rowKey++;
      }

      setCalcResults(results.length > 0 ? results : "No XP gain possible.");
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <h1>Pokemon Go! Pidgey Evolution XP Calculator</h1>
          <p id="pidgeyCalcExplanation">
            This progam calculates the maximum experience a player can earn in
            Pokemon Go! for pidgey evolutions given the initial number of
            pidgey, pidgeotto, and pidgey candies the player has. <br />
            This program was originally written in c++ as well as java for a
            university project with slightly different rules and stipulations
            than the actual Pokemon Go! game. The version presented here was
            rewritten in JavaScript and follows the actual Pokemon Go! rules,
            which were that 12 candies were required to evolve a pidgey and 1
            pidgey or pidgeotto could be transferred for 1 candy each. The
            program outputs the transfers and evolutions necessary to gain the
            most possible experience and outputs the total XP after each
            evolution.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>Please enter initial values below:</h2>
          <form onSubmit={handleSubmit}>
            <div className={styles.inputRow}>
              <div>
                <label htmlFor="pidgeyInput">Pidgey: </label>
                <input
                  id="pidgeyInput"
                  defaultValue="0"
                  onChange={(event) =>
                    inputChangeHandler(event, setPidgeyCount)
                  }
                />
              </div>
              <div>
                <label htmlFor="pidgeottoInput">Pidgeotto: </label>
                <input
                  id="pidgeottoInput"
                  defaultValue="0"
                  onChange={(event) =>
                    inputChangeHandler(event, setPidgeottoCount)
                  }
                />
              </div>
              <div>
                <label htmlFor="candyInput">Candies: </label>
                <input
                  id="candyInput"
                  defaultValue="0"
                  onChange={(event) => inputChangeHandler(event, setCandyCount)}
                />
              </div>
            </div>
            {inputErrorMessage}
            <div>
              <button id="getXP" className="btn btn-primary mobile-float-right">
                Get XP
              </button>
            </div>
          </form>
        </Col>
      </Row>
      <Row>
        <Col className="my-2">{calcResults}</Col>
      </Row>
    </Container>
  );
};

export default PidgeyCalc;
