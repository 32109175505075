import React from 'react';
import styles from './PrimaryButton.module.css';

const PrimaryButton = (props) => {
    return (
        <button className={`${styles.defaultStyles} btn btn-primary`} onClick={props.onClick}>
            {props.label}
        </button>
    )
}

export default PrimaryButton;
