import React from 'react';
import PhotoCard from '../../../components/UI/PhotoCard/PhotoCard';
import styles from './Gallery.module.css';
import GalleryOptions from './GalleryOptions';

let PHOTO_DATA = [
    {
        id: 'photo1',
        img_path: require('../../../static/photoGallery/media/vancouver_frolf.jpeg').default,
        title: 'Disc Golf in Washington',
        description: 'Scenic wooded picnic area.',
        categories: ['Nature', 'Washington'],
        albums: [],
    },
    {
        id: 'photo2',
        img_path: require('../../../static/photoGallery/media/hawaiian_path.jpeg').default,
        title: 'Hawaiian Vacation',
        description: 'A Hawaiian forest trail.',
        categories: ['Nature', 'Hawaii'],
        albums: [],
    },
    {
        id: 'photo3',
        img_path: require('../../../static/photoGallery/media/basketball_hoop.jpeg').default,
        title: 'Basketball',
        description: '',
        categories: ['Basketball', 'Black & White'],
        albums: [],
    },
    {
        id: 'photo5',
        img_path: require('../../../static/photoGallery/media/plane_sunset.jpeg').default,
        title: 'Float Plane at Sunset',
        description: 'A photo of a float plane sitting lakeside at sunset.',
        categories: ['Aviation', 'Sunset', 'Alaska'],
        albums: [],
    },
    {
        id: 'photo9',
        img_path: require('../../../static/photoGallery/media/mustang_bw.jpeg').default,
        title: '2020 Ford Mustang',
        description: '',
        categories: ['Music', 'Microphone'],
        albums: [],
    },
    {
        id: 'photo8',
        img_path: require('../../../static/photoGallery/media/smoky_mic.jpeg').default,
        title: 'Smoky Microphone',
        description: '',
        categories: ['Music', 'Microphone'],
        albums: [],
    },
    {
        id: 'photo7',
        img_path: require('../../../static/photoGallery/media/wolf_guitar.jpeg').default,
        title: 'Lone Wolf Guitar',
        description: 'I love the design of this guitar, with a landscape made of various wood grains and wolf howling at the moon.',
        categories: ['Music', 'Guitar', 'Wolf/Wolves'],
        albums: [],
    },
    {
        id: 'photo6',
        img_path: require('../../../static/photoGallery/media/microphone.jpeg').default,
        title: 'Microphone',
        description: 'A photo of a microphone taken during a band practice.',
        categories: ['Music', 'Microphone', 'Black & White'],
        albums: [],
    },
    {
        id: 'photo4',
        img_path: require('../../../static/photoGallery/media/concrete_path.jpeg').default,
        title: 'Concrete Path',
        description: 'Just a photo of a random sidewalk that reminds me of a specific time in my life.',
        categories: ['Black & White'],
        albums: [],
    },
]

const Gallery = () => {
    return (
        <div className={styles['gallery-wrapper']}>
            <GalleryOptions />
            <div className={styles['gallery']}>
                {
                    PHOTO_DATA.map(photo => (
                        <PhotoCard 
                            key={photo.id}
                            img_path={photo.img_path}
                            title={photo.title}
                            description={photo.description}
                            categories={photo.categories}
                            albums={photo.albums}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default Gallery
