import React from 'react';
import Backdrop from '../Backdrop/Backdrop';
import styles from './LoadingSpinner.module.css';

const LoadingSpinner = (props) => {
    let spinnerText = props.message? props.message : "Loading";

    return (
        <div>
            <Backdrop />
            <div className={styles.overlay}>
                <span className={styles.loadingText}>
                    {spinnerText}
                    <span className={styles.dotOne}>.</span>
                    <span className={styles.dotTwo}>.</span>
                    <span className={styles.dotThree}>.</span>
                </span>
            </div>
        </div>
    )
}

export default LoadingSpinner;