import React, { useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import awsDiagram from '../../static/images/aws-serverless-diagram.png';
import awsDiagramDark from '../../static/images/dark-theme-aws-serverless-diagram.png';
import styles from './AboutAWSArchitecture.module.css';
import { AppContext } from "../../store/AppContext";

const AboutAWSArchitecture = () => {
    const appCtx = useContext(AppContext);

    let serverlessDiagram = appCtx.darkMode ? (
        <img src={awsDiagramDark} alt="AWS Serverless Diagram" />
    ) : (
        <img src={awsDiagram} alt="AWS Serverless Diagram" />
    );

    return (
        <Container>
            <h1>About the Site AWS Architecture</h1>
            <Row>
                <Col>
                    <p>
                        When I first created this website I built it with the Django
                        framework and deployed it with AWS Elastic Beanstalk. Maintaining
                        and adding to the site has been a great way to learn more
                        about the AWS ecosystem, which has enabled me to transition the
                        site to a much more cost effective serverless deployment.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Django vs. React with AWS Lambda</h2>
                    <p>
                        I've greatly enjoyed working with the Django framework, however
                        it seems to be ill suited for serverless architectures and therefore
                        I have converted the frontend of the site into a React app which leverages REST API(s) 
                        created with AWS lambda running Python. Utilizing AWS lambdas running 
                        Python allowed me to realatively easily port much of my Django (Python) code to
                        my new serverless design.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>The Serverless Design</h2>
                    <p>
                        This is a relatively common design pattern, and many AWS articles can be found
                        detailing the benefits and trade-offs. Migrating the frontend of the website from
                        a provisioned server (for example EC2) to an S3 static website can drastically reduce
                        the cost. Since Elastic Beanstalk leverages EC2 instances it is "always on" and therefore
                        always acruing server fees. By contrast, an S3 static site deployment is
                        serverless and therefore acrues charges per request, which in my case results in significant savings.

                    </p>
                    <div className={styles.diagram}>
                        {serverlessDiagram}
                    </div>
                    <h3>Cloudfront</h3>
                    <p>
                        Traffic for (www.)nnmcdonald-portfilio.com is directed to Cloudfront
                        distributions, which route traffic to corrsponding S3 buckets. 
                        Using a Cloudfront distribution allows for the use of an Amazon
                        Certificate Manager (ACM) issued TLS certificate (which S3 alone does not).
                        Additionally any http traffic is redirected to https.
                    </p>
                    <h3>S3 Static Sites</h3>
                    <p>
                        The frontend of the website has been redesigned in React and is now served as an S3
                        static website. Traffic to www.nnmcdonald-portfolio.com is sent straight to 
                        the S3 bucket, and by using a second Cloudfront distribution and S3 bucket any
                        traffic to nnmcdonald-portfolio.com is redirected to www.nnmcdonald-portfolio.com.
                        The second S3 bucket is simply configured as a static site which redirects to the subdomain.
                    </p>
                    <h3>API Gateway & Lambda</h3>
                    <p>
                        By leveraging API gateway and Lambda I'm able to manage REST API endpoints
                        where the backend logic previously handled by Django may be maintained.
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default AboutAWSArchitecture;
