import React, { useContext } from 'react';
import styles from './Foundation.module.css';
import { FreeCellContext } from '../../../store/FreeCellContext';
import PlayingCard from '../../../components/PlayingCard/PlayingCard';

const Foundation = (props) => {
  const freeCellContext = useContext(FreeCellContext);
  let currentFoundation = freeCellContext.gameState[0][props.foundationIndex];
  let topCardIndex = currentFoundation.length - 1;
  let foundation = null;

  const cardClickHandler = () => {
    if(freeCellContext.cardSelection && (freeCellContext.cardSelection !== 0 || props.foundationIndex !== freeCellContext.cardSelection[0])) {
      // try to add card selection to foundation
      let src = freeCellContext.cardSelection;

      if(src[2] === 2) {
        let selectedCascade = freeCellContext.gameState[2][src[0]];
        src[1] = selectedCascade.length - 1;
    }

      let gameAction = {
        type: "moveToFoundation",
        source: src,
        destination: props.foundationIndex
      }

      let sourceCard = freeCellContext.gameState[src[2]][src[0]][src[1]];

      if(topCardIndex < 0) {
        if(sourceCard.rank === 1)
          freeCellContext.dispatchGameState(gameAction);
      } else {
        let destinationCard = freeCellContext.gameState[0][props.foundationIndex][topCardIndex];

        if(destinationCard.rank + 1 === sourceCard.rank && sourceCard.suit === destinationCard.suit) {
          freeCellContext.dispatchGameState(gameAction);
        }
      }
      
      freeCellContext.setCardSelection(null);
    } else if(freeCellContext.cardSelection && props.foundationIndex === freeCellContext.cardSelection[0]) {
      freeCellContext.setCardSelection(null);
    } else if(topCardIndex >= 0) {
      freeCellContext.setCardSelection([props.foundationIndex, topCardIndex, 0]);
    }
  }

  if(freeCellContext.gameState[0][props.foundationIndex].length - 1 >= 0) {
    let topCardIndex = freeCellContext.gameState[0][props.foundationIndex].length - 1;
    let topCard = freeCellContext.gameState[0][props.foundationIndex][topCardIndex];
    foundation = (
      <div className={styles.cardWrapper} >
          <PlayingCard 
              rank={topCard.rank}
              suit={topCard.suit}
              faceGraphic={topCard.faceGraphic}
              cardIndex={0}
              highlighted={freeCellContext.cardSelection && freeCellContext.cardSelection[2] === 0 && props.foundationIndex === freeCellContext.cardSelection[0]}
              onClick={cardClickHandler}
          />
      </div>
    );
  }
  
  return (
    foundation !== null ?
      <div className={styles.foundation}>{foundation}</div>
      :
      <div className={styles.foundationEmpty} onClick={cardClickHandler}>A</div>
  )
}

export default Foundation

// import React, { useContext } from 'react';
// import styles from './Foundation.module.css';
// import { FreeCellContext } from '../../../store/FreeCellContext';
// import PlayingCard from '../../../components/PlayingCard/PlayingCard';

// const Foundation = (props) => {
//   const freeCellContext = useContext(FreeCellContext);
//   let foundation = null;

//   const cardClickHandler = () => {
//     let currentFoundation = freeCellContext.gameState[0][props.foundationIndex];
//     let topCardIndex = currentFoundation.length - 1;
    
//     if(freeCellContext.cardSelection && (freeCellContext.cardSelection !== 0 || props.foundationIndex !== freeCellContext.cardSelection[0])) {
//       // try to add card selection to foundation
//       let src = [...freeCellContext.cardSelection];

//       if(src[2] === 2) {
//         let selectedCascade = freeCellContext.gameState[2][src[0]];
//         src[1] = selectedCascade.length - 1;
//     }

//       let gameAction = {
//         type: "moveToFoundation",
//         source: src,
//         destination: props.foundationIndex
//       }

//       let sourceCard = freeCellContext.gameState[src[2]][src[0]][src[1]];

//       if(topCardIndex < 0) {
//         if(sourceCard.rank === 1)
//           freeCellContext.dispatchGameState(gameAction);
//       } else {
//         let destinationCard = freeCellContext.gameState[0][props.foundationIndex][topCardIndex];

//         if(destinationCard.rank + 1 === sourceCard.rank && sourceCard.suit === destinationCard.suit) {
//           freeCellContext.dispatchGameState(gameAction);
//         }
//       }
      
//       freeCellContext.setCardSelection(null);
//     } else if(topCardIndex >= 0) {
//       freeCellContext.setCardSelection([props.foundationIndex, topCardIndex, 0]);
//     }
//   }

//   const getFoundation = () => {
//     if(freeCellContext.gameState[0][props.foundationIndex].length - 1 >= 0) {
//       console.log(freeCellContext.gameState[0][props.foundationIndex]);
//       let topCardIndex = freeCellContext.gameState[0][props.foundationIndex].length - 1;
//       let topCard = freeCellContext.gameState[0][props.foundationIndex][topCardIndex];
//       return (
//         <div className={styles.foundation}>
//         <div className={styles.cardWrapper} >
//             <PlayingCard 
//                 rank={topCard.rank}
//                 suit={topCard.suit}
//                 cardIndex={0}
//                 highlighted={freeCellContext.cardSelection && freeCellContext.cardSelection[2] === 0 && props.foundationIndex === freeCellContext.cardSelection[0]}
//                 onClick={cardClickHandler}
//             />
//         </div>
//         </div>
//       );
//     } else {
//       return (
//         <div className={styles.foundationEmpty} onClick={cardClickHandler}>A</div>
//       )
//     }
//   }
  
//   return getFoundation();
// }

// export default Foundation